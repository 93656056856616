import { add, divide, floor, max, min, multiply, norm, subtract } from 'mathjs';
import { memo, useEffect, useMemo } from 'react';
import { useMutation, UseMutationExecute } from 'urql';
import {
  calculateRelativePosition,
  scalePointAlongDirection,
} from '../../shapes/util';
import {
  LinearRing2,
  LineSegment2,
  Point2,
  Polygon2,
} from '../../../../domain/geometry/geometric-types';
import { SlopedInsulationCrossSection } from '../SheetShapeDrawing';
import { SheetPolygon } from '../SheetPolygon';
import { getUnitScaleFromCalibration } from '../../../../domain/sheet-calibration';
import { VERTICAL_MULTIPLIER } from '../SheetDrainLineDrawing';
import { useSheetViewer } from '../../../common/SheetViewer';
import {
  BulkDeleteSparkelPropertiesDocument,
  BulkDeleteSparkelPropertiesInput,
  BulkDeleteSparkelPropertiesMutation,
  BulkUpsertSparkelPropertiesDocument,
  BulkUpsertSparkelPropertiesInput,
  BulkUpsertSparkelPropertiesMutation,
  Exact,
} from '../../../../gql/graphql';
import { getSheetShapeUrn } from '../../../../services/viewer/SheetShapesManager';
import { Substrate } from '../../ProductChoices';
import { getTaperedInsulationBuildup } from './tapered-insulation-products';
import { useInsulationProductSlices } from './hooks/insulation-slices';

export type Trapezoid2 = [Point2, Point2, Point2, Point2];

export type RectangularProduct = {
  name: string;
  sku: string;
  thermalConductivity: number;
  dimensions: {
    height: number;
    length?: number;
  };
};

export type SlopedProduct = {
  name: string;
  sku: string;
  thermalConductivity: number;
  dimensions: {
    minHeight: number;
    maxHeight: number;
    length: number;
  };
};

const OFFSET_FROM_ROOF_VC = 50;

export const TaperedInsulation = memo(
  ({
    dbId,
    crossSection,
    currentProductAreas,
    currentRoofUValue,
    getPointInViewerCoordinateSystem,
    isSelected,
    onClick,
    roofShapeDbId,
    substrate,
    buildingHeight,
  }: {
    dbId: number;
    crossSection: SlopedInsulationCrossSection;
    currentProductAreas: Record<string, number>;
    currentRoofUValue: number | null;
    getPointInViewerCoordinateSystem: (pointInPdf: Point2) => Point2;
    isSelected?: boolean;
    onClick?: React.MouseEventHandler;
    roofShapeDbId?: number;
    substrate: Substrate;
    buildingHeight: number;
  }) => {
    const {
      calibration: { calibration },
      shapesManager: { renderedSheetShapes },
      protanVisuals: {
        isShowingTaperedInsulationSlices,
        isShowingPreAssembledFastenerSlices,
      },
    } = useSheetViewer();

    const currentBuildup = useMemo(
      () => getTaperedInsulationBuildup(substrate),
      [substrate]
    );

    const unitScale = useMemo(
      () =>
        calibration ? getUnitScaleFromCalibration(calibration.calibration) : 1,
      [calibration]
    );

    const existingPolygonShapes = useMemo(() => {
      return Object.values(renderedSheetShapes).flatMap((shapesForUrn) =>
        shapesForUrn.filter((shape) => shape.sheetShapePolygon !== null)
      );
    }, [renderedSheetShapes]);

    const { trapezoids: crossSectionTrapezoids, maxHeight: maxHeightVC } =
      useMemo(
        () =>
          computeTrapezoids(
            crossSection.baseLine,
            crossSection.lowPoints,
            crossSection.minHeight / unitScale,
            crossSection.slope
          ),
        [
          crossSection.baseLine,
          crossSection.lowPoints,
          crossSection.minHeight,
          crossSection.slope,
          unitScale,
        ]
      );

    const offsetDirection = useMemo(
      () => [
        // OffsetDirection is perpendicular to the base line
        crossSection.baseLine[1][1] - crossSection.baseLine[0][1],
        crossSection.baseLine[0][0] - crossSection.baseLine[1][0],
      ],
      [crossSection]
    );

    const offsetDirectionNormalized = useMemo(
      () => [
        offsetDirection[0] / Math.hypot(offsetDirection[0], offsetDirection[1]),
        offsetDirection[1] / Math.hypot(offsetDirection[0], offsetDirection[1]),
      ],
      [offsetDirection]
    );

    const offsetVectorVC = useMemo(
      () => [
        offsetDirectionNormalized[0] * (OFFSET_FROM_ROOF_VC + maxHeightVC),
        offsetDirectionNormalized[1] * (OFFSET_FROM_ROOF_VC + maxHeightVC),
      ],
      [offsetDirectionNormalized, maxHeightVC]
    );

    const crossSectionTrapezoidsOffsetted: Trapezoid2[] = useMemo(
      () =>
        crossSectionTrapezoids.map(
          (trapezoid) =>
            trapezoid.map((point) => add(point, offsetVectorVC)) as Trapezoid2
        ),
      [crossSectionTrapezoids, offsetVectorVC]
    );

    const baseLayerProductSegments = useMemo(
      () =>
        crossSectionTrapezoidsOffsetted.map((trapezoid) =>
          computeBaseLayerFromTrapezoid(
            trapezoid,
            currentBuildup.baseLayer,
            unitScale
          )
        ),
      [crossSectionTrapezoidsOffsetted, currentBuildup.baseLayer, unitScale]
    );

    const topLayerProductSegments = useMemo(
      () =>
        crossSectionTrapezoidsOffsetted.map((trapezoid) =>
          computeTopLayerFromTrapezoid(
            trapezoid,
            currentBuildup.topLayer,
            unitScale
          )
        ),
      [crossSectionTrapezoidsOffsetted, currentBuildup.topLayer, unitScale]
    );

    const slopedInsulationSegmentsPerTrapezoid = useMemo(
      () =>
        crossSectionTrapezoidsOffsetted.map((trapezoid) =>
          computeSlopedInsulationFromTrapezoid(
            trapezoid,
            currentBuildup.slopedInsulationLayer,
            currentBuildup.topLayer,
            unitScale
          )
        ),
      [
        crossSectionTrapezoidsOffsetted,
        currentBuildup.slopedInsulationLayer,
        currentBuildup.topLayer,
        unitScale,
      ]
    );

    const rectangularInsulationSegmentsPerTrapezoid = useMemo(
      () =>
        crossSectionTrapezoidsOffsetted.map((trapezoid, index) =>
          computeRectangularInsulationFromSlopedSegments(
            slopedInsulationSegmentsPerTrapezoid[index],
            trapezoid,
            currentBuildup.insulationLayer,
            currentBuildup.baseLayer,
            unitScale
          )
        ),
      [
        crossSectionTrapezoidsOffsetted,
        currentBuildup.baseLayer,
        currentBuildup.insulationLayer,
        slopedInsulationSegmentsPerTrapezoid,
        unitScale,
      ]
    );

    const productPolygonsPerSegment = useMemo(() => {
      const baseLayerProductPolygons = baseLayerProductSegments.map((segment) =>
        segment.trapezoids.map((trapezoid) => trapezoidToPolygon2(trapezoid))
      );

      const topLayerProductPolygons = topLayerProductSegments.flatMap(
        (segment) => [
          segment.trapezoids.map((trapezoid) => trapezoidToPolygon2(trapezoid)),
        ]
      );

      const slopedInsulationTrapezoids =
        slopedInsulationSegmentsPerTrapezoid.flatMap((mainTrapezoid) =>
          mainTrapezoid.map((segment) =>
            segment.trapezoids.map((trapezoid) =>
              trapezoidToPolygon2(trapezoid)
            )
          )
        );

      const rectangularInsulationPolygons =
        rectangularInsulationSegmentsPerTrapezoid
          .map((segments) => getRectangularPolygons(segments))
          .flatMap((trapezoid) => trapezoid.map(trapezoidToPolygon2));

      return [
        ...baseLayerProductPolygons,
        rectangularInsulationPolygons,
        ...topLayerProductPolygons,
        ...slopedInsulationTrapezoids,
      ];
    }, [
      baseLayerProductSegments,
      rectangularInsulationSegmentsPerTrapezoid,
      slopedInsulationSegmentsPerTrapezoid,
      topLayerProductSegments,
    ]);

    const scalingOriginVC = useMemo(
      () =>
        add(crossSectionTrapezoids[0][0], [
          offsetDirectionNormalized[0] * OFFSET_FROM_ROOF_VC,
          offsetDirectionNormalized[1] * OFFSET_FROM_ROOF_VC,
        ]) as Point2,
      [crossSectionTrapezoids, offsetDirectionNormalized]
    );

    const scaledProductPolygonsPerSegment = useMemo(() => {
      return productPolygonsPerSegment.map((polygonSegment) =>
        polygonSegment.map(
          (polygon) =>
            polygon.exterior.map((point) =>
              scalePointAlongDirection(
                point,
                scalingOriginVC,
                offsetDirectionNormalized as Point2,
                VERTICAL_MULTIPLIER
              )
            ) as LinearRing2
        )
      );
    }, [productPolygonsPerSegment, scalingOriginVC, offsetDirectionNormalized]);

    const roofShape = useMemo(() => {
      return existingPolygonShapes.find(
        (shape) => shape.dbId === roofShapeDbId
      );
    }, [existingPolygonShapes, roofShapeDbId]);

    const {
      insulationProductSlices,
      productListWithArea: insulationProductListWithArea,
      roofUValue,
    } = useInsulationProductSlices({
      slopedInsulationSegmentsPerTrapezoid,
      rectangularInsulationSegmentsPerTrapezoid,
      crossSection,
      roofShape,
      unitScale,
    });

    const [, bulkUpsertSparkelProps] = useMutation(
      BulkUpsertSparkelPropertiesDocument
    );

    const [, bulkDeleteSparkelProperties] = useMutation(
      BulkDeleteSparkelPropertiesDocument
    );

    const productListWithArea = useMemo(
      () => ({
        ...insulationProductListWithArea,
      }),
      [insulationProductListWithArea]
    );

    // Update the sparkel properties areas when the product list changes
    useEffect(() => {
      if (productListWithArea && currentProductAreas && roofShape?.projectId) {
        const promises = [];

        // Handle new and updated products
        for (const sku in productListWithArea) {
          if (currentProductAreas[sku]) {
            const areaDiff = Math.abs(
              currentProductAreas[sku] - productListWithArea[sku]
            );

            if (areaDiff < 0.01) {
              continue;
            }
          }

          const promise = updateSparkelProperties(
            dbId,
            sku,
            productListWithArea[sku].toString(),
            roofShape?.projectId,
            bulkUpsertSparkelProps
          );

          promises.push(promise);
        }

        // Handle removed products
        for (const sku in currentProductAreas) {
          if (!productListWithArea[sku]) {
            // Product was removed, delete the sparkel property
            const promise = deleteSparkelProperties(
              roofShape?.projectId,
              dbId,
              sku,
              bulkDeleteSparkelProperties
            );

            promises.push(promise);
          }
        }

        Promise.all(promises).then(() => {
          console.log('Updated sparkel properties');
        });
      }
    }, [
      bulkDeleteSparkelProperties,
      bulkUpsertSparkelProps,
      currentProductAreas,
      dbId,
      productListWithArea,
      roofShape?.projectId,
    ]);

    // Update the Sparkel properties with the roof U-value
    useEffect(() => {
      if (
        roofUValue &&
        roofShape?.projectId &&
        currentRoofUValue !== roofUValue
      ) {
        updateSparkelProperties(
          dbId,
          'RoofUValue',
          roofUValue.toString(),
          roofShape?.projectId,
          bulkUpsertSparkelProps
        );
      }
    }, [
      roofUValue,
      roofShape?.projectId,
      dbId,
      bulkUpsertSparkelProps,
      currentRoofUValue,
    ]);

    // Add error handling for polygon rendering
    const safePolygons = useMemo(() => {
      try {
        return scaledProductPolygonsPerSegment.flat().map((polygon) => ({
          exterior: polygon,
          interiors: [],
        }));
      } catch (error) {
        console.warn('Error processing polygons:', error);
        return [];
      }
    }, [scaledProductPolygonsPerSegment]);

    return (
      <>
        <SheetPolygon
          multipolygon={{
            polygons: safePolygons,
          }}
          getPointInDomCoordinateSystem={getPointInViewerCoordinateSystem}
          fill={isSelected ? 'blue.200' : 'orange.100'}
          stroke={isSelected ? 'blue.500' : 'orange.300'}
          onClick={onClick}
          selectable
        />

        {isShowingTaperedInsulationSlices &&
          insulationProductSlices?.map((polygonsWithColor, index) => (
            <SheetPolygon
              key={index}
              multipolygon={{
                polygons: (polygonsWithColor.polygons || []).filter((p) => {
                  try {
                    // Validate polygon before rendering
                    return p && p.exterior && p.exterior.length >= 4;
                  } catch (error) {
                    console.warn('Invalid polygon:', error);
                    return false;
                  }
                }),
              }}
              getPointInDomCoordinateSystem={getPointInViewerCoordinateSystem}
              fill={polygonsWithColor.color}
              stroke={polygonsWithColor.color}
              fillOpacity={0.15}
              strokeWidth={1}
            />
          ))}
      </>
    );
  },
  (prev, next) => {
    return (
      prev.crossSection === next.crossSection &&
      prev.isSelected === next.isSelected &&
      prev.getPointInViewerCoordinateSystem ===
        next.getPointInViewerCoordinateSystem
    );
  }
);

export function computeTrapezoids(
  baseline: LineSegment2,
  lowPoints: number[],
  minHeight: number,
  slope: number
): { trapezoids: Trapezoid2[]; maxHeight: number } {
  const [p0, p1] = baseline;
  const [x0, y0] = p0;
  const [x1, y1] = p1;

  // Direction vector components
  const dx = x1 - x0;
  const dy = y1 - y0;

  // Length of the baseline
  const L = Math.hypot(dx, dy);

  // Normal vector components (perpendicular to baseline)
  const n: Point2 = [-dy / L, dx / L];

  // Sort low points
  const sortedLowPoints = [...lowPoints].sort((a, b) => a - b);

  // Generate all points including maxPoints
  const allPoints: Array<{ position: number; isLow: boolean }> = [];

  // Add start point
  allPoints.push({ position: 0, isLow: false });

  // Add all lowPoints and maxPoints between them
  for (let i = 0; i < sortedLowPoints.length; i++) {
    allPoints.push({ position: sortedLowPoints[i], isLow: true });

    // Add maxPoint between this lowPoint and next one if it exists
    if (i < sortedLowPoints.length - 1) {
      const midPoint = (sortedLowPoints[i] + sortedLowPoints[i + 1]) / 2;
      allPoints.push({ position: midPoint, isLow: false });
    }
  }

  // Add end point
  allPoints.push({ position: 1, isLow: false });

  const trapezoids: Trapezoid2[] = [];
  let maxHeight = 0;

  // Process each pair of points to create trapezoids
  for (let i = 0; i < allPoints.length - 1; i++) {
    const point1 = allPoints[i];
    const point2 = allPoints[i + 1];

    // Calculate heights at segment endpoints
    const h1 = point1.isLow
      ? minHeight
      : minHeight +
        slope *
          L *
          Math.abs(
            point1.position -
              (point1.position < point2.position
                ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  sortedLowPoints.find((p) => p > point1.position)!
                : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  sortedLowPoints.filter((p) => p < point1.position).pop()!)
          );

    const h2 = point2.isLow
      ? minHeight
      : minHeight +
        slope *
          L *
          Math.abs(
            point2.position -
              (point2.position < point1.position
                ? sortedLowPoints.find((p) => p > point2.position)!
                : sortedLowPoints.filter((p) => p < point2.position).pop()!)
          );

    maxHeight = Math.max(maxHeight, h1, h2);

    // Calculate base points
    const basePoint1: Point2 = [
      x0 + point1.position * dx,
      y0 + point1.position * dy,
    ];

    const basePoint2: Point2 = [
      x0 + point2.position * dx,
      y0 + point2.position * dy,
    ];

    // Calculate top points
    const topPoint1: Point2 = [
      basePoint1[0] + h1 * n[0],
      basePoint1[1] + h1 * n[1],
    ];

    const topPoint2: Point2 = [
      basePoint2[0] + h2 * n[0],
      basePoint2[1] + h2 * n[1],
    ];

    // Create trapezoid
    const trapezoid: Trapezoid2 = [
      basePoint1,
      basePoint2,
      topPoint2,
      topPoint1,
    ];

    trapezoids.push(trapezoid);
  }

  return { trapezoids, maxHeight };
}

TaperedInsulation.displayName = 'TaperedInsulation';

export function trapezoidToPolygon2(trapezoid: Trapezoid2): Polygon2 {
  const [p0, pt, topT, top0] = trapezoid;

  return {
    exterior: [p0, pt, topT, top0, p0] as LinearRing2,
    interiors: [],
  };
}

export function computeBaseLayerFromTrapezoid(
  trapezoid: Trapezoid2,
  baseLayer: RectangularProduct | undefined,
  unitScale: number
): RectangularProductSegment {
  if (!baseLayer) {
    return {
      products: [],
      trapezoids: [],
    };
  }

  const { baseLine, normal } = getBaselineProperties(trapezoid);

  const baseLayerTrapezoid: Trapezoid2 = [
    baseLine[0], // Base start point
    baseLine[1], // Base end point
    add(
      baseLine[1],
      multiply(baseLayer.dimensions.height / unitScale, normal)
    ) as Point2, // Top end point
    add(
      baseLine[0],
      multiply(baseLayer.dimensions.height / unitScale, normal)
    ) as Point2, // Top start point
  ];

  return {
    products: [baseLayer],
    trapezoids: [baseLayerTrapezoid],
  };
}

export function computeTopLayerFromTrapezoid(
  trapezoid: Trapezoid2,
  topLayer: RectangularProduct,
  unitScale: number
): RectangularProductSegment {
  // Extract points from the main trapezoid
  const [p0, pt, topT, top0] = trapezoid;

  // Height (thickness) of the top layer product
  const { height } = topLayer.dimensions;

  // Left side vector from top0 to p0
  const v_left: Point2 = [p0[0] - top0[0], p0[1] - top0[1]];
  const len_left = Math.hypot(v_left[0], v_left[1]);
  const u_left: Point2 = [v_left[0] / len_left, v_left[1] / len_left];

  // Right side vector from topT to pt
  const v_right: Point2 = [pt[0] - topT[0], pt[1] - topT[1]];
  const len_right = Math.hypot(v_right[0], v_right[1]);
  const u_right: Point2 = [v_right[0] / len_right, v_right[1] / len_right];

  // Bottom points of the top layer trapezoid
  const bottom0: Point2 = [
    top0[0] + u_left[0] * (height / unitScale),
    top0[1] + u_left[1] * (height / unitScale),
  ];
  const bottomT: Point2 = [
    topT[0] + u_right[0] * (height / unitScale),
    topT[1] + u_right[1] * (height / unitScale),
  ];

  // Top layer trapezoid points
  const topLayerTrapezoid: Trapezoid2 = [
    top0, // Top start point
    topT, // Top end point
    bottomT, // Bottom end point
    bottom0, // Bottom start point
  ];

  return {
    products: [topLayer],
    trapezoids: [topLayerTrapezoid],
  };
}

export type SlopedProductSegment = {
  products: SlopedProduct[];
  trapezoids: Trapezoid2[];
};

export type RectangularProductSegment = {
  products: RectangularProduct[];
  trapezoids: Trapezoid2[];
};

export function computeSlopedInsulationFromTrapezoid(
  trapezoid: Trapezoid2,
  products: SlopedProduct[],
  topLayer?: RectangularProduct,
  unitScale = 1
): SlopedProductSegment[] {
  // 1. Get the baseline properties
  const properties = getBaselineProperties(trapezoid);

  // 2. Find out how many segments are needed
  const fullSegmentLength = products.reduce(
    (acc, layer) => acc + layer.dimensions.length,
    0
  );
  const numFullSegments = floor(
    (properties.baseLineLength * unitScale) / fullSegmentLength
  );

  if (numFullSegments > 100) {
    console.warn('Too many segments');
    return [];
  }

  // 3. Get the segments
  return getSlopedProductSegments(
    properties,
    numFullSegments,
    products,
    topLayer,
    unitScale
  );
}

type TrapezoidProperties = {
  baseLine: LineSegment2;
  baseLineLength: number;
  tangent: Point2;
  normal: Point2;
  maxHeight: number;
  minHeight: number;
};

function getBaselineProperties(trapezoid: Trapezoid2): TrapezoidProperties {
  const [p0, pt, topT, top0] = trapezoid;

  const h1 = norm(subtract(top0, p0)) as number;
  const h2 = norm(subtract(topT, pt)) as number;

  const slopeSign = Math.sign(h2 - h1);

  const baseLine =
    slopeSign > 0 ? ([p0, pt] as LineSegment2) : ([pt, p0] as LineSegment2);

  const baseLineDirection = subtract(baseLine[1], baseLine[0]) as Point2;
  const baseLineLength = norm(baseLineDirection) as number;
  const baseLineDirectionNormalized = divide(
    baseLineDirection,
    baseLineLength
  ) as Point2;
  const normalVector =
    slopeSign > 0
      ? ([
          -baseLineDirectionNormalized[1],
          baseLineDirectionNormalized[0],
        ] as Point2)
      : ([
          baseLineDirectionNormalized[1],
          -baseLineDirectionNormalized[0],
        ] as Point2);

  const maxHeight = Math.max(h1, h2);
  const minHeight = Math.min(h1, h2);

  return {
    baseLine,
    baseLineLength,
    tangent: baseLineDirectionNormalized,
    normal: normalVector,
    maxHeight,
    minHeight,
  };
}

const MIN_SEGMENT_LENGTH = 0.1;

function getSlopedProductSegments(
  baseLineProperties: TrapezoidProperties,
  numFullSegments: number,
  products: SlopedProduct[],
  topLayer?: RectangularProduct,
  unitScale = 1
): SlopedProductSegment[] {
  let topLayerOffsetVC = 0;

  if (topLayer) {
    const topLayerHeightVC = topLayer.dimensions.height / unitScale;
    const slope =
      (baseLineProperties.maxHeight - baseLineProperties.minHeight) /
      baseLineProperties.baseLineLength;
    topLayerOffsetVC = Math.cos(Math.atan(slope)) * topLayerHeightVC;
  }

  // Define global offset as the min height of the trapezoid minus the minimum height of the last product along the baseline normal
  const globalOffsetVC = multiply(
    baseLineProperties.minHeight -
      min(products.map((p) => p.dimensions.minHeight)) / unitScale -
      topLayerOffsetVC,
    baseLineProperties.normal
  );

  const offsetPerSegmentMagnitude =
    max(products.map((p) => p.dimensions.maxHeight)) -
    min(products.map((p) => p.dimensions.minHeight));

  const offsetPerSegmentVC = multiply(
    offsetPerSegmentMagnitude / unitScale,
    baseLineProperties.normal
  ) as Point2;

  const segments: SlopedProductSegment[] = [];

  const fullSegmentLength = products.reduce(
    (acc, layer) => acc + layer.dimensions.length,
    0
  );

  for (let i = 0; i < numFullSegments; i++) {
    const productOffsetVC = add(
      add(multiply(offsetPerSegmentVC, i), globalOffsetVC), // Global offset
      multiply(baseLineProperties.tangent, (fullSegmentLength / unitScale) * i) // Offset for the full segments
    ) as Point2;

    const productsInSegment = getSlopedProductsInSegment(
      baseLineProperties,
      products,
      productOffsetVC,
      unitScale
    );

    if (productsInSegment) {
      segments.push(productsInSegment);
    }
  }

  const remainingLength =
    baseLineProperties.baseLineLength * unitScale -
    fullSegmentLength * numFullSegments;

  if (remainingLength > 0) {
    // Remove the last element from the products array until the sum of all product lengths is less than the remaining length

    let remainingProducts = products.slice(); // Copy the array
    let lastPoppedProduct: SlopedProduct | undefined;

    while (
      remainingProducts.reduce(
        (acc, layer) => acc + layer.dimensions.length,
        0
      ) > remainingLength
    ) {
      lastPoppedProduct = remainingProducts.pop();
    }

    const productOffsetVC = add(
      add(
        multiply(
          (offsetPerSegmentMagnitude / unitScale) * numFullSegments,
          baseLineProperties.normal
        ) as Point2,
        globalOffsetVC
      ),
      multiply(
        baseLineProperties.tangent,
        (fullSegmentLength / unitScale) * numFullSegments
      )
    ) as Point2;

    const productsInSegment = getSlopedProductsInSegment(
      baseLineProperties,
      remainingProducts,
      productOffsetVC,
      unitScale
    );

    if (productsInSegment) {
      segments.push(productsInSegment);
    }

    const lastSegmentLength = remainingProducts.reduce(
      (acc, layer) => acc + layer.dimensions.length,
      0
    );

    const newRemainingLength =
      baseLineProperties.baseLineLength * unitScale -
      fullSegmentLength * numFullSegments -
      lastSegmentLength;

    if (lastPoppedProduct && newRemainingLength > MIN_SEGMENT_LENGTH) {
      const productOffsetVC = add(
        add(
          multiply(
            (offsetPerSegmentMagnitude / unitScale) * numFullSegments,
            baseLineProperties.normal
          ) as Point2,
          globalOffsetVC
        ),
        multiply(
          baseLineProperties.tangent,
          (fullSegmentLength / unitScale) * numFullSegments +
            lastSegmentLength / unitScale
        )
      ) as Point2;

      const lastModifiedSegment = getLastModifiedSegment(
        baseLineProperties,
        lastPoppedProduct,
        newRemainingLength,
        productOffsetVC,
        unitScale
      );

      if (lastModifiedSegment) {
        segments.push(lastModifiedSegment);
      }
    }
  }

  return segments;
}

const getSlopedProductsInSegment = (
  baseLineProperties: TrapezoidProperties,
  products: SlopedProduct[],
  offsetVC: Point2,
  unitScale: number
): SlopedProductSegment | null => {
  if (products.length === 0) {
    return null;
  }

  const productsInSegment: SlopedProduct[] = [];
  const trapzoidsInSegment: Trapezoid2[] = [];

  let traversedLength = 0;

  for (const product of products) {
    const productStartPointVC = add(
      add(
        baseLineProperties.baseLine[0],
        multiply(traversedLength / unitScale, baseLineProperties.tangent)
      ),
      offsetVC
    ) as Point2;

    const productEndPointVC = add(
      add(
        baseLineProperties.baseLine[0],
        multiply(
          traversedLength / unitScale + product.dimensions.length / unitScale,
          baseLineProperties.tangent
        )
      ),
      offsetVC
    ) as Point2;

    const productTrapezoid: Trapezoid2 = [
      productStartPointVC,
      productEndPointVC,
      add(
        productEndPointVC,
        multiply(
          product.dimensions.maxHeight / unitScale,
          baseLineProperties.normal
        )
      ) as Point2,
      add(
        productStartPointVC,
        multiply(
          product.dimensions.minHeight / unitScale,
          baseLineProperties.normal
        )
      ) as Point2,
    ];

    productsInSegment.push(product);
    trapzoidsInSegment.push(productTrapezoid);

    traversedLength += product.dimensions.length;
  }

  return { products: productsInSegment, trapezoids: trapzoidsInSegment };
};

const getLastModifiedSegment = (
  baseLineProperties: TrapezoidProperties,
  product: SlopedProduct,
  remainingLength: number,
  offsetVC: Point2,
  unitScale: number
): SlopedProductSegment | null => {
  const modifiedProduct = {
    ...product,
    dimensions: {
      ...product.dimensions,
      length: remainingLength,
      // Adjust the maxHeight so the slope remains the same
      maxHeight:
        product.dimensions.minHeight +
        ((product.dimensions.maxHeight - product.dimensions.minHeight) *
          remainingLength) /
          product.dimensions.length,
    },
  };

  return getSlopedProductsInSegment(
    baseLineProperties,
    [modifiedProduct],
    offsetVC,
    unitScale
  );
};

export function computeRectangularInsulationFromSlopedSegments(
  slopedProductSegments: SlopedProductSegment[],
  mainTrapezoid: Trapezoid2,
  products: RectangularProduct[],
  baseLayer?: RectangularProduct,
  unitScale = 1
): RectangularProductSegment[] {
  const rectangularInsulation: RectangularProductSegment[] = [];

  const filteredSlopedProductSegments = slopedProductSegments.filter(
    (s) => s.trapezoids.length > 0
  );

  // 1. For each segment, create a rectangular product segment
  for (const slopedProductSegment of filteredSlopedProductSegments) {
    const rectangularSegment = getRectangularProductSegment(
      slopedProductSegment,
      mainTrapezoid,
      products,
      baseLayer,
      unitScale
    );

    if (rectangularSegment) {
      rectangularInsulation.push(rectangularSegment);
    }
  }

  return rectangularInsulation;
}

function getRectangularProductSegment(
  slopedSegment: SlopedProductSegment,
  mainTrapezoid: Trapezoid2,
  productCandidates: RectangularProduct[],
  baseLayer?: RectangularProduct,
  unitScale = 1
): RectangularProductSegment | null {
  const [p0] = slopedSegment.trapezoids[0]; // Bottom left point of the first trapezoid
  const { baseLine, normal, tangent } = getBaselineProperties(mainTrapezoid);

  const t = calculateRelativePosition(p0, baseLine);

  const projectedPointOnBaseline = [
    baseLine[0][0] + t * (baseLine[1][0] - baseLine[0][0]),
    baseLine[0][1] + t * (baseLine[1][1] - baseLine[0][1]),
  ] as Point2;

  const segmentHeight =
    (norm(subtract(p0, projectedPointOnBaseline)) as number) * unitScale -
    (baseLayer?.dimensions.height || 0);

  const segmentWidth = slopedSegment.products.reduce(
    (acc, p) => acc + p.dimensions.length,
    0
  );

  if (segmentWidth <= 0 || segmentHeight <= 0) {
    return null;
  }

  const products = fillGapWithProducts(segmentHeight, productCandidates);
  const trapezoids: Trapezoid2[] = [];

  for (let i = 0; i < products.length; i++) {
    const product = products[i];
    const offset = products
      .slice(0, i)
      .reduce(
        (acc, p) => acc + p.dimensions.height,
        baseLayer?.dimensions.height || 0
      );

    const trapezoid: Trapezoid2 = [
      add(
        projectedPointOnBaseline,
        multiply(product.dimensions.height / unitScale, normal)
      ) as Point2, // Top start point
      add(
        add(
          projectedPointOnBaseline,
          multiply(product.dimensions.height / unitScale, normal)
        ),
        multiply(segmentWidth / unitScale, tangent)
      ) as Point2, // Top end point
      add(
        projectedPointOnBaseline,
        multiply(segmentWidth / unitScale, tangent)
      ) as Point2, // Base end point
      projectedPointOnBaseline, // Base start point
    ];

    // Move the trapezoid to the correct position
    trapezoid[0] = add(
      trapezoid[0],
      multiply(offset / unitScale, normal)
    ) as Point2;
    trapezoid[1] = add(
      trapezoid[1],
      multiply(offset / unitScale, normal)
    ) as Point2;
    trapezoid[2] = add(
      trapezoid[2],
      multiply(offset / unitScale, normal)
    ) as Point2;
    trapezoid[3] = add(
      trapezoid[3],
      multiply(offset / unitScale, normal)
    ) as Point2;

    trapezoids.push(trapezoid);
  }

  return { products, trapezoids };
}

function fillGapWithProducts(
  gap: number,
  productCandidates: RectangularProduct[],
  tolerance = 0.01
) {
  // 1. Start by filling with the largest product
  // 2. If the gap is still not filled, continue with the next largest product
  // 3. The same product can be used multiple times

  let remainingGap = gap;

  const products: RectangularProduct[] = [];

  while (remainingGap > 0) {
    // Find the largest product that fits the remaining gap
    const product = productCandidates.find(
      (p) => p.dimensions.height <= remainingGap + tolerance
    );

    if (!product) {
      break;
    }

    products.push(product);
    remainingGap -= product.dimensions.height;
  }

  return products;
}

// eslint-disable-next-line complexity
function getRectangularPolygons(
  segments: RectangularProductSegment[]
): Trapezoid2[] {
  if (segments.length <= 1) return [];

  // eslint-disable-next-line complexity
  function isValidTrapezoid(trap: Trapezoid2): boolean {
    const EPSILON = 0.0001;
    const DOT_PRODUCT_EPSILON = 0.01;

    // Extract points
    const p0 = trap[0];
    const p1 = trap[1];
    const p2 = trap[2];
    const p3 = trap[3];

    // Helper functions
    const vec = (a: Point2, b: Point2): [number, number] => [
      b[0] - a[0],
      b[1] - a[1],
    ];
    const length = (v: [number, number]): number =>
      Math.sqrt(v[0] * v[0] + v[1] * v[1]);
    const normalize = (v: [number, number]): [number, number] => {
      const mag = length(v);
      return mag > EPSILON ? [v[0] / mag, v[1] / mag] : [0, 0];
    };
    const dot = (v1: [number, number], v2: [number, number]): number =>
      v1[0] * v2[0] + v1[1] * v2[1];

    // Compute side vectors
    const v0 = vec(p0, p1);
    const v1 = vec(p1, p2);
    const v2 = vec(p2, p3);
    const v3 = vec(p3, p0);

    // Check side lengths are not degenerate
    const l0 = length(v0);
    const l1 = length(v1);
    const l2 = length(v2);
    const l3 = length(v3);

    if (l0 < EPSILON || l1 < EPSILON || l2 < EPSILON || l3 < EPSILON) {
      return false;
    }

    // Normalize vectors for angle checks
    const n0 = normalize(v0);
    const n1 = normalize(v1);
    const n2 = normalize(v2);
    const n3 = normalize(v3);

    // Check angles: each corner should be 90 degrees.
    // For a rectangle, (v0 · v1) ~ 0, (v1 · v2) ~ 0, etc.
    const angle0 = Math.abs(dot(n0, n1));
    const angle1 = Math.abs(dot(n1, n2));
    const angle2 = Math.abs(dot(n2, n3));
    const angle3 = Math.abs(dot(n3, n0));

    if (
      angle0 > DOT_PRODUCT_EPSILON ||
      angle1 > DOT_PRODUCT_EPSILON ||
      angle2 > DOT_PRODUCT_EPSILON ||
      angle3 > DOT_PRODUCT_EPSILON
    ) {
      return false;
    }

    // Check opposite sides have the same length (rectangle property)
    // Since it's a quadrilateral, we just need l0 ~ l2 and l1 ~ l3
    const lengthDiff1 = Math.abs(l0 - l2);
    const lengthDiff2 = Math.abs(l1 - l3);

    if (lengthDiff1 > EPSILON || lengthDiff2 > EPSILON) {
      return false;
    }

    return true;
  }

  // Helper function to merge two adjacent trapezoids
  function mergeTrapezoids(trap1: Trapezoid2, trap2: Trapezoid2): Trapezoid2 {
    // Points are ordered: top-left, top-right, bottom-right, bottom-left
    // Just take the appropriate points from each trapezoid
    return [
      [...trap1[0]], // from first trapezoid
      [...trap2[1]], // from second trapezoid
      [...trap2[2]], // from second trapezoid
      [...trap1[3]], // from first trapezoid
    ];
  }

  const mergedTrapezoids: Trapezoid2[] = [];

  // For each product level
  const maxProductsInSegment = Math.max(
    ...segments.map((s) => s.products.length)
  );

  for (
    let productIndex = 0;
    productIndex < maxProductsInSegment;
    productIndex++
  ) {
    let currentMergedGroup: {
      trapezoid: Trapezoid2;
      height: number;
    } | null = null;

    // Go through each segment
    for (let segmentIndex = 0; segmentIndex < segments.length; segmentIndex++) {
      const segment = segments[segmentIndex];

      // Skip if this segment doesn't have a product at this level
      if (productIndex >= segment.products.length) {
        // If we were merging something, add it and reset
        if (
          currentMergedGroup &&
          isValidTrapezoid(currentMergedGroup.trapezoid)
        ) {
          mergedTrapezoids.push(currentMergedGroup.trapezoid);
        }
        currentMergedGroup = null;
        continue;
      }

      const currentProduct = segment.products[productIndex];
      const currentTrapezoid = segment.trapezoids[productIndex];

      if (!currentMergedGroup) {
        // Start a new merge group
        currentMergedGroup = {
          trapezoid: currentTrapezoid,
          height: currentProduct.dimensions.height,
        };
      } else if (
        Math.abs(currentMergedGroup.height - currentProduct.dimensions.height) <
        0.0001
      ) {
        // Merge with existing group if heights match
        currentMergedGroup.trapezoid = mergeTrapezoids(
          currentMergedGroup.trapezoid,
          currentTrapezoid
        );
      } else {
        // Different height, save current group and start new one
        if (isValidTrapezoid(currentMergedGroup.trapezoid)) {
          mergedTrapezoids.push(currentMergedGroup.trapezoid);
        }
        currentMergedGroup = {
          trapezoid: currentTrapezoid,
          height: currentProduct.dimensions.height,
        };
      }
    }

    // Add the last merged group if there is one
    if (currentMergedGroup && isValidTrapezoid(currentMergedGroup.trapezoid)) {
      mergedTrapezoids.push(currentMergedGroup.trapezoid);
    }
  }

  return mergedTrapezoids;
}

async function updateSparkelProperties(
  dbid: number,
  propertySet: string,
  propertyValue: string,
  projectId: string,
  bulkUpsertSparkelProps: UseMutationExecute<
    BulkUpsertSparkelPropertiesMutation,
    Exact<{
      input: BulkUpsertSparkelPropertiesInput;
    }>
  >
) {
  await bulkUpsertSparkelProps({
    input: {
      projectId,
      dbIds: [
        {
          modelUrn: getSheetShapeUrn(projectId),
          dbIds: [dbid],
        },
      ],
      thePropertySet: propertySet,
      thePropertyValue: propertyValue,
    },
  });
}

async function deleteSparkelProperties(
  projectId: string,
  dbId: number,
  propertySet: string,
  bulkDeleteSparkelProperties: UseMutationExecute<
    BulkDeleteSparkelPropertiesMutation,
    Exact<{
      input: BulkDeleteSparkelPropertiesInput;
    }>
  >
) {
  const dbIds = [
    {
      modelUrn: getSheetShapeUrn(projectId),
      dbIds: [dbId],
    },
  ];

  await bulkDeleteSparkelProperties({
    input: { projectId, dbIds, thePropertySet: propertySet },
  });
}
