import { useMemo } from 'react';
import { add, divide, max, min, multiply, norm, subtract } from 'mathjs';
import { Substrate } from '../../../ProductChoices';
import { SlopedInsulationCrossSection } from '../../SheetShapeDrawing';
import { Trapezoid2, trapezoidToPolygon2 } from '../SlopedInsulation';
import {
  LineSegment2,
  Point2,
  Polygon2,
} from 'src/domain/geometry/geometric-types';
import { SheetShapeDeepFragment } from 'src/gql/graphql';
import { calculateRelativePosition } from 'src/components/orderContractor/shapes/util';
import { getColorValueFromGradient } from 'src/utils/color-util';
import { GRADIENTS } from 'src/utils/row-color-util';
import {
  toMultiPolygon2,
  toPolygon2,
} from 'src/domain/geometry/algorithms/util/type-mapping';
import {
  intersectPolygons,
  polygonDifference,
  toJstsPolygon,
} from 'src/domain/geometry/algorithms/util/polygon';
import { calculateSheetShapeCornerAreas } from 'src/domain/geometry/algorithms/sheet-shape-corner';
import { calculateSheetShapeRandfelt } from 'src/domain/geometry/algorithms/sheet-shape-randfelt';

export type PreAssembledFastenerProduct = {
  name: string;
  sku: string;
  dimensions: {
    minInsulationDepth: number;
    maxInsulationDepth: number;
  };
};

export type PreAssembledFastenerSlice = {
  polygons: Polygon2[] | undefined;
  color: string;
  product: PreAssembledFastenerProduct;
};

type UsePreAssembledFastenerSlicesReturn = {
  preAssembledFastenerSlices: PreAssembledFastenerSlice[];
  productListWithArea: Record<string, number>;
};

export function usePreAssembledFastenerSlices(
  crossSection: SlopedInsulationCrossSection,
  roofShape: SheetShapeDeepFragment,
  substrate: Substrate,
  buildingHeight: number,
  unitScale: number
): UsePreAssembledFastenerSlicesReturn {
  const preAssembledFastenerSlices = useMemo(() => {
    if (!crossSection || !roofShape.sheetShapePolygon?.multipolygon.polygons) {
      return [];
    }

    // Step 1: Get available fastener products
    const fastenerProducts =
      getPreAssembledFastenerProductsForSubstrate(substrate);

    const fastenersByPosition = calculateFastenerRanges(
      crossSection,
      unitScale,
      fastenerProducts
    );

    // Step 5: Create rectangular polygons for each fastener range
    const basePolygons = createBasePolygons(
      roofShape,
      crossSection.baseLine,
      fastenersByPosition
    );

    // Step 6: Intersect with roof shape to get final polygons
    const intersections =
      roofShape.sheetShapePolygon?.multipolygon.polygons.flatMap(
        (shapePolygon) =>
          Array.from(basePolygons.values()).flatMap((value) =>
            value.polygons.flatMap((polygon) => {
              try {
                const intersections = intersectPolygons(
                  toPolygon2(shapePolygon),
                  polygon
                );

                return {
                  polygons: intersections,
                  color: value.color,
                  product: value.product,
                };
              } catch (error) {
                console.warn('Intersection calculation failed:', error);
                return [];
              }
            })
          )
      );

    // Step 7: Create slices
    const preAssembledFastenerSlices = intersections.map((value) => ({
      polygons: value.polygons,
      color: value.color,
      product: value.product,
    })) as PreAssembledFastenerSlice[];

    return preAssembledFastenerSlices;
  }, [crossSection, roofShape, substrate, unitScale]);

  const cornerZonePolygons = useMemo(() => {
    if (!roofShape?.sheetShapePolygon?.multipolygon) return null;

    return calculateSheetShapeCornerAreas(
      toMultiPolygon2(roofShape?.sheetShapePolygon?.multipolygon),
      buildingHeight,
      unitScale
    ).multiPolygon;
  }, [roofShape?.sheetShapePolygon?.multipolygon, buildingHeight, unitScale]);

  const randfeltPolygons = useMemo(() => {
    if (!roofShape?.sheetShapePolygon?.multipolygon) return null;

    return calculateSheetShapeRandfelt(
      toMultiPolygon2(roofShape?.sheetShapePolygon?.multipolygon),
      buildingHeight,
      unitScale
    ).multiPolygon;
  }, [roofShape?.sheetShapePolygon?.multipolygon, buildingHeight, unitScale]);

  const edgeZonePolygons = useMemo(() => {
    if (!roofShape?.sheetShapePolygon?.multipolygon) return null;
    if (!cornerZonePolygons || !randfeltPolygons) return null;

    // Remove corner zones from randfelt polygons to get edge zones
    const edgeZones = randfeltPolygons.polygons.flatMap((polygon) => {
      // Start with the original polygon as a single-element array
      let remainingPolygons = [polygon];

      // Subtract each corner zone from all remaining polygons
      for (const cornerZone of cornerZonePolygons.polygons) {
        try {
          remainingPolygons = remainingPolygons.flatMap((poly) =>
            polygonDifference(poly, cornerZone, 0.1)
          );
        } catch (error) {
          console.warn('Polygon difference calculation failed:', error);
        }
      }

      return remainingPolygons;
    });

    return edgeZones;
  }, [
    roofShape?.sheetShapePolygon?.multipolygon,
    cornerZonePolygons,
    randfeltPolygons,
  ]);

  const midZonePolygons = useMemo(() => {
    if (!randfeltPolygons || !roofShape?.sheetShapePolygon?.multipolygon)
      return null;

    const roofShapePolygons = toMultiPolygon2(
      roofShape?.sheetShapePolygon?.multipolygon
    );

    return roofShapePolygons.polygons.flatMap((polygon) => {
      // Subtract the randfelt polygons
      try {
        return randfeltPolygons?.polygons.flatMap((randfelt) =>
          polygonDifference(polygon, randfelt, 0.1)
        );
      } catch (error) {
        console.warn('Polygon difference calculation failed:', error);
        return [];
      }
    });
  }, [randfeltPolygons, roofShape?.sheetShapePolygon?.multipolygon]);

  const fastenersInCornerZones = useMemo(() => {
    if (!cornerZonePolygons) return null;

    return cornerZonePolygons.polygons.flatMap((cornerZone) =>
      preAssembledFastenerSlices.flatMap((slice) => {
        const product = slice.product;

        return slice.polygons?.flatMap((polygon) => {
          try {
            const intersection = intersectPolygons(cornerZone, polygon);
            const area =
              intersection?.reduce((acc, polygon) => {
                return acc + toJstsPolygon(polygon).getArea() * unitScale ** 2;
              }, 0) || 0;

            return {
              product,
              area,
            };
          } catch (error) {
            console.warn('Intersection calculation failed:', error);
            return [];
          }
        });
      })
    );
  }, [preAssembledFastenerSlices, cornerZonePolygons, unitScale]);

  const fastenersInEdgeZones = useMemo(() => {
    if (!edgeZonePolygons) return null;

    return edgeZonePolygons.flatMap((edgeZone) =>
      preAssembledFastenerSlices.flatMap((slice) => {
        return slice.polygons?.flatMap((polygon) => {
          try {
            const intersection = intersectPolygons(edgeZone, polygon);
            const area =
              intersection?.reduce((acc, polygon) => {
                return acc + toJstsPolygon(polygon).getArea() * unitScale ** 2;
              }, 0) || 0;

            return {
              product: slice.product,
              area,
            };
          } catch (error) {
            console.warn('Intersection calculation failed:', error);
            return [];
          }
        });
      })
    );
  }, [preAssembledFastenerSlices, edgeZonePolygons, unitScale]);

  const fastenersInMidZones = useMemo(() => {
    if (!midZonePolygons) return null;

    return midZonePolygons.flatMap((midZone) =>
      preAssembledFastenerSlices.flatMap((slice) =>
        slice.polygons?.flatMap((polygon) => {
          try {
            const intersection = intersectPolygons(midZone, polygon);
            const area =
              intersection?.reduce((acc, polygon) => {
                return acc + toJstsPolygon(polygon).getArea() * unitScale ** 2;
              }, 0) || 0;

            return {
              product: slice.product,
              area,
            };
          } catch (error) {
            console.warn('Intersection calculation failed:', error);
            return [];
          }
        })
      )
    );
  }, [midZonePolygons, preAssembledFastenerSlices, unitScale]);

  const productListWithArea = useMemo(() => {
    const productList: Record<string, number> = {};
    if (fastenersInCornerZones && fastenersInCornerZones.length > 0) {
      fastenersInCornerZones.forEach((fastener) => {
        if (fastener && fastener.area > 0) {
          productList[fastener.product.sku + ' (corner)'] = fastener.area;
        }
      });
    }
    if (fastenersInEdgeZones && fastenersInEdgeZones.length > 0) {
      fastenersInEdgeZones.forEach((fastener) => {
        if (fastener && fastener.area > 0) {
          productList[fastener.product.sku + ' (edge)'] = fastener.area;
        }
      });
    }
    if (fastenersInMidZones && fastenersInMidZones.length > 0) {
      fastenersInMidZones.forEach((fastener) => {
        if (fastener && fastener.area > 0) {
          productList[fastener.product.sku + ' (mid)'] = fastener.area;
        }
      });
    }
    return productList;
  }, [fastenersInCornerZones, fastenersInEdgeZones, fastenersInMidZones]);

  return { preAssembledFastenerSlices, productListWithArea };
}

function getPreAssembledFastenerProductsForSubstrate(
  substrate: Substrate
): PreAssembledFastenerProduct[] {
  if (substrate === Substrate.Steel || substrate === Substrate.Wood) {
    return PREASSEMBLED_FASTENER_PRODUCTS_STEEL_AND_TIMBER;
  }
  return PREASSEMBLED_FASTENER_PRODUCTS_CONCRETE;
}

interface HeightPoint {
  position: number; // Position along baseline normalized to 0-1
  height: number; // Height at position in meters
}
interface FastenerRange {
  product: PreAssembledFastenerProduct;
  startPos: number;
  endPos: number;
}

function createBasePolygons(
  roofShape: SheetShapeDeepFragment,
  baseLine: LineSegment2,
  fastenersByPosition: FastenerRange[]
): Map<
  string,
  { polygons: Polygon2[]; color: string; product: PreAssembledFastenerProduct }
> {
  const baseLineDirection = subtract(baseLine[1], baseLine[0]) as Point2;

  const baselineLength = norm(baseLine) as number;

  const baseLineDirectionNormalized = divide(
    baseLineDirection,
    baselineLength
  ) as Point2;

  const normal = [
    -baseLineDirectionNormalized[1],
    baseLineDirectionNormalized[0],
  ] as Point2;

  // Project shape points perpendicular to the base line to find the extent of the roof shape
  const tValues = roofShape.sheetShapePolygon?.multipolygon.polygons.flatMap(
    (polygon) =>
      polygon.exterior.points.map((point) =>
        calculateRelativePosition([point.x, point.y], [[0, 0], normal])
      )
  );

  if (!tValues || tValues.length === 0) return new Map();

  const roofDepth = max(tValues) - min(tValues);

  const polygonsWithColor = new Map<
    string,
    {
      polygons: Polygon2[];
      color: string;
      product: PreAssembledFastenerProduct;
    }
  >();

  const minInsulationDepth = Math.min(
    ...fastenersByPosition.map(
      (range) => range.product.dimensions.minInsulationDepth
    )
  );
  const maxInsulationDepth = Math.max(
    ...fastenersByPosition.map(
      (range) => range.product.dimensions.minInsulationDepth
    )
  );

  for (const range of fastenersByPosition) {
    const factor =
      maxInsulationDepth === minInsulationDepth
        ? 0
        : (range.product.dimensions.minInsulationDepth - minInsulationDepth) /
          (maxInsulationDepth - minInsulationDepth);

    const color = getColorValueFromGradient(GRADIENTS[3], factor); // Green to red gradient

    const rangePolygon = createRectangularPolygon(
      baseLine,
      roofDepth,
      range.startPos * baselineLength,
      range.endPos * baselineLength
    );

    const existing = polygonsWithColor.get(range.product.sku) || {
      polygons: [],
      color: '',
      product: range.product,
    };

    polygonsWithColor.set(range.product.sku, {
      polygons: [...existing.polygons, rangePolygon],
      color,
      product: range.product,
    });
  }

  return polygonsWithColor;
}

function createRectangularPolygon(
  baseLine: LineSegment2,
  sliceDepth: number,
  startPos: number,
  endPos: number
): Polygon2 {
  const baselineLength = norm(baseLine) as number;

  const tangentVector = divide(
    subtract(baseLine[1], baseLine[0]),
    baselineLength
  ) as Point2;

  const normalVector = [tangentVector[1], -tangentVector[0]] as Point2;

  const p1 = add(baseLine[0], multiply(tangentVector, startPos)) as Point2;
  const p2 = add(baseLine[0], multiply(tangentVector, endPos)) as Point2;
  const p3 = add(p2, multiply(normalVector, -sliceDepth)) as Point2;
  const p4 = add(p1, multiply(normalVector, -sliceDepth)) as Point2;

  const trapezoid = [p1, p2, p3, p4] as Trapezoid2;

  return trapezoidToPolygon2(trapezoid);
}

function calculateHeightAtPosition(
  position: number,
  crossSection: SlopedInsulationCrossSection,
  unitScale: number
): number {
  const baselineLength = norm(crossSection.baseLine) as number;

  const lowPoints = [...crossSection.lowPoints].sort((a, b) => a - b);

  // If we're at the start or end, these are highPoints
  if (position === 0 || position === 1) {
    const nearestLowPoint = lowPoints.reduce(
      (nearest, point) =>
        Math.abs(point - position) < Math.abs(nearest - position)
          ? point
          : nearest,
      lowPoints[0]
    );
    const distanceToLowPoint =
      Math.abs(position - nearestLowPoint) * baselineLength;
    return (
      crossSection.minHeight +
      crossSection.slope * distanceToLowPoint * unitScale
    );
  }

  // Find the segment this position belongs to
  let leftLowPoint = -1;
  let rightLowPoint = 2; // Initialize outside valid range

  for (let i = 0; i < lowPoints.length - 1; i++) {
    if (position >= lowPoints[i] && position <= lowPoints[i + 1]) {
      leftLowPoint = lowPoints[i];
      rightLowPoint = lowPoints[i + 1];
      break;
    }
  }

  // If we're at a lowPoint, return minHeight
  if (lowPoints.includes(position)) {
    return crossSection.minHeight;
  }

  // Calculate nearest lowPoint and distance to it
  const nearestLowPoint = lowPoints.reduce(
    (nearest, point) =>
      Math.abs(point - position) < Math.abs(nearest - position)
        ? point
        : nearest,
    lowPoints[0]
  );

  // Calculate highPoint if we're between two lowPoints
  if (leftLowPoint !== -1 && rightLowPoint !== 2) {
    const highPoint = (leftLowPoint + rightLowPoint) / 2;
    if (position === highPoint) {
      const distanceToLowPoint =
        Math.abs(position - leftLowPoint) * baselineLength;
      return (
        crossSection.minHeight +
        crossSection.slope * distanceToLowPoint * unitScale
      );
    }
  }

  // For all other positions, interpolate linearly based on distance to nearest lowPoint
  const distanceToLowPoint =
    Math.abs(position - nearestLowPoint) * baselineLength;
  return (
    crossSection.minHeight + crossSection.slope * distanceToLowPoint * unitScale
  );
}

export const PREASSEMBLED_FASTENER_PRODUCTS_STEEL_AND_TIMBER: PreAssembledFastenerProduct[] =
  [
    // For Steel and Timber substrates
    {
      name: 'Protan EcotekT/JT2 ST2 65/60 Hylse m/pigg 65mm/JT2 ST2 6,0x60mm. Isolasjon 90-100',
      sku: '39003076',
      dimensions: {
        minInsulationDepth: 0.09,
        maxInsulationDepth: 0.1,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 65/80 Hylse m/pigg 65mm/JT2 ST2 6,0x80mm. Isolasjon 110-120',
      sku: '39003077',
      dimensions: {
        minInsulationDepth: 0.11,
        maxInsulationDepth: 0.12,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 85/100 Hylse m/pigg 85mm/JT2 ST2 6,0x100mm. Isolasjon 130-140',
      sku: '39003078',
      dimensions: {
        minInsulationDepth: 0.13,
        maxInsulationDepth: 0.14,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 105/80 Hylse m/pigg 105mm/JT2 ST2 6,0x80mm. Isolasjon 150-160',
      sku: '39003079',
      dimensions: {
        minInsulationDepth: 0.15,
        maxInsulationDepth: 0.16,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 105/100 Hylse m/pigg 105mm/JT2 ST2 6,0x100mm. Isolasjon 170',
      sku: '39003080',
      dimensions: {
        minInsulationDepth: 0.17,
        maxInsulationDepth: 0.17,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 135/80 Hylse m/pigg 135mm/JT2 ST2 6,0x80mm. Isolasjon 180-190',
      sku: '39003081',
      dimensions: {
        minInsulationDepth: 0.18,
        maxInsulationDepth: 0.19,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 135/100 Hylse m/pigg 135mm/JT2 ST2 6,0x100mm. Isolasjon 200',
      sku: '39003082',
      dimensions: {
        minInsulationDepth: 0.2,
        maxInsulationDepth: 0.2,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 165/80 Hylse m/pigg 165mm/JT2 ST2 6,0x80mm. Isolasjon 210-220',
      sku: '39003083',
      dimensions: {
        minInsulationDepth: 0.21,
        maxInsulationDepth: 0.22,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 165/100 Hylse m/pigg 165mm/JT2 ST2 6,0x100mm. Isolasjon 230',
      sku: '39003084',
      dimensions: {
        minInsulationDepth: 0.23,
        maxInsulationDepth: 0.23,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 195/80 Hylse m/pigg 195mm/JT2 ST2 6,0x80mm. Isolasjon 240-250',
      sku: '39003085',
      dimensions: {
        minInsulationDepth: 0.24,
        maxInsulationDepth: 0.25,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 195/100 Hylse m/pigg 195mm/JT2 ST2 6,0x100mm. Isolasjon 260',
      sku: '39003086',
      dimensions: {
        minInsulationDepth: 0.26,
        maxInsulationDepth: 0.26,
      },
    },
    {
      name: 'Protan Ecotk-T/JT2 250-225/60 Hylse 225 mm Skrue 6,0 x 60 mm',
      sku: '39003087',
      dimensions: {
        minInsulationDepth: 0.25,
        maxInsulationDepth: 0.25,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 225/80 Hylse m/pigg 225mm/JT2 ST2 6,0x80mm. Isolasjon 270-280',
      sku: '39003088',
      dimensions: {
        minInsulationDepth: 0.27,
        maxInsulationDepth: 0.28,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 225/100 Hylse m/pigg 225mm/JT2 ST2 6,0x100mm. Isolasjon 290-300',
      sku: '39003089',
      dimensions: {
        minInsulationDepth: 0.29,
        maxInsulationDepth: 0.3,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 225/120 Hylse m/pigg 225mm/JT2 ST2 6,0x120mm. Isolasjon 310-320',
      sku: '39003090',
      dimensions: {
        minInsulationDepth: 0.31,
        maxInsulationDepth: 0.32,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 225/160 Hylse m/pigg 225mm/JT2 ST2 6,0x160mm. Isolasjon 330-340',
      sku: '39003091',
      dimensions: {
        minInsulationDepth: 0.33,
        maxInsulationDepth: 0.34,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 225/180 Hylse m/pigg 225mm/JT2 ST2 6,0x180mm. Isolasjon 350-360',
      sku: '39003092',
      dimensions: {
        minInsulationDepth: 0.35,
        maxInsulationDepth: 0.36,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 225/180 Hylse m/pigg 225mm/JT2 ST2 6,0x180mm. Isolasjon 370-380',
      sku: '39003093',
      dimensions: {
        minInsulationDepth: 0.37,
        maxInsulationDepth: 0.38,
      },
    },
    {
      name: 'Protan EcotekT/JT2 ST2 225/200 Hylse m/pigg 225mm/JT2 ST2 6,0x200mm. Isolasjon 390-400',
      sku: '39003094',
      dimensions: {
        minInsulationDepth: 0.39,
        maxInsulationDepth: 0.4,
      },
    },
  ];

export const PREASSEMBLED_FASTENER_PRODUCTS_CONCRETE: PreAssembledFastenerProduct[] =
  [
    // For Concrete substrate
    {
      name: 'JBS-R/EcoTek-70-100-65/80 Hylse 65mm/JBS-R 7,5x80mm, isolasjon 70-100',
      sku: '39003137',
      dimensions: {
        minInsulationDepth: 0.07,
        maxInsulationDepth: 0.1,
      },
    },
    {
      name: 'JBS-R/EcoTek-90-120-85/80 Hylse 85mm/JBS-R 7,5x80mm, isolasjon 90-120',
      sku: '39003138',
      dimensions: {
        minInsulationDepth: 0.09,
        maxInsulationDepth: 0.12,
      },
    },
    {
      name: 'JBS-R/EcoTek-110-180-105/120 Hylse 105mm/JBS-R 7,5x120mm, isolasjon 110-180',
      sku: '39003139',
      dimensions: {
        minInsulationDepth: 0.11,
        maxInsulationDepth: 0.18,
      },
    },
    {
      name: 'JBS-R/EcoTek-170-270-165/150 Hylse 165mm/JBS-R 7,5x150mm, isolasjon 170-270',
      sku: '39003140',
      dimensions: {
        minInsulationDepth: 0.17,
        maxInsulationDepth: 0.27,
      },
    },
    {
      name: 'JBS-R/EcoTek-230-390-225/210 Hylse 225mm/JBS-R 7,5x210mm, isolasjon 230-390',
      sku: '39003141',
      dimensions: {
        minInsulationDepth: 0.23,
        maxInsulationDepth: 0.39,
      },
    },
    {
      name: 'JBS-R/EcoTek-340-500-335/210 Hylse 335mm/JBS-R 7,5x210mm, isolasjon 340-500',
      sku: '39003142',
      dimensions: {
        minInsulationDepth: 0.34,
        maxInsulationDepth: 0.5,
      },
    },
    {
      name: 'JBS-R/EcoTek-340-590-335/300 Hylse 335mm/JBS-R 7,5x300mm, isolasjon 340-590',
      sku: '39003143',
      dimensions: {
        minInsulationDepth: 0.34,
        maxInsulationDepth: 0.59,
      },
    },
  ];

type FastenerHeightRange = {
  product: PreAssembledFastenerProduct;
  minHeight: number;
  maxHeight: number;
};

function calculateNonOverlappingFastenerRanges(
  fastenerProducts: PreAssembledFastenerProduct[]
): FastenerHeightRange[] {
  // Sort products by minInsulationDepth
  const sortedProducts = [...fastenerProducts].sort(
    (a, b) => a.dimensions.minInsulationDepth - b.dimensions.minInsulationDepth
  );

  const ranges: FastenerHeightRange[] = [];
  let currentMin = sortedProducts[0].dimensions.minInsulationDepth;

  for (let i = 0; i < sortedProducts.length; i++) {
    const current = sortedProducts[i];
    const next = sortedProducts[i + 1];

    ranges.push({
      product: current,
      minHeight: currentMin,
      maxHeight: next
        ? Math.min(
            current.dimensions.maxInsulationDepth,
            next.dimensions.minInsulationDepth
          )
        : current.dimensions.maxInsulationDepth,
    });

    if (next) {
      currentMin = ranges[ranges.length - 1].maxHeight;
    }
  }

  return ranges;
}

export function getPreAssembledFastenerProductForInsulationDepthAndSubstrate(
  insulationDepth: number,
  substrate: Substrate
) {
  if (substrate === Substrate.Steel || substrate === Substrate.Wood) {
    return PREASSEMBLED_FASTENER_PRODUCTS_STEEL_AND_TIMBER.find(
      (product) =>
        insulationDepth >= product.dimensions.minInsulationDepth &&
        insulationDepth <= product.dimensions.maxInsulationDepth
    );
  }

  if (substrate === Substrate.Concrete) {
    return PREASSEMBLED_FASTENER_PRODUCTS_CONCRETE.find(
      (product) =>
        insulationDepth >= product.dimensions.minInsulationDepth &&
        insulationDepth <= product.dimensions.maxInsulationDepth
    );
  }

  return null;
}

function calculateFastenerRanges(
  crossSection: SlopedInsulationCrossSection,
  unitScale: number,
  fastenerProducts: PreAssembledFastenerProduct[]
): FastenerRange[] {
  // Get non-overlapping height ranges for products
  const heightRanges = calculateNonOverlappingFastenerRanges(fastenerProducts);

  // Get all significant height points along the baseline
  const heightPoints = getSignificantHeightPoints(
    crossSection,
    heightRanges,
    unitScale
  );

  // Create initial ranges based on these points
  const initialRanges: FastenerRange[] = [];
  for (let i = 0; i < heightPoints.length - 1; i++) {
    const currentPoint = heightPoints[i];
    const nextPoint = heightPoints[i + 1];

    // Find applicable product for this segment (using midpoint height)
    const midHeight = (currentPoint.height + nextPoint.height) / 2;
    const applicableRange = heightRanges.find(
      (range) => midHeight >= range.minHeight && midHeight <= range.maxHeight
    );

    if (applicableRange) {
      initialRanges.push({
        product: applicableRange.product,
        startPos: currentPoint.position,
        endPos: nextPoint.position,
      });
    } else {
      console.log(
        'Could not find applicable range at position and height',
        currentPoint.position,
        midHeight
      );
    }
  }

  // Merge adjacent ranges with the same product
  const mergedRanges: FastenerRange[] = [];
  let currentRange: FastenerRange | null = null;

  for (const range of initialRanges) {
    if (!currentRange) {
      currentRange = { ...range };
      continue;
    }

    if (
      currentRange.product.sku === range.product.sku &&
      Math.abs(currentRange.endPos - range.startPos) < 1e-10 // Handle floating point comparison
    ) {
      // Merge ranges
      currentRange.endPos = range.endPos;
    } else {
      // Different product or gap between ranges
      mergedRanges.push(currentRange);
      currentRange = { ...range };
    }
  }

  // Add the last range if it exists
  if (currentRange) {
    mergedRanges.push(currentRange);
  }

  return mergedRanges;
}

interface HeightPoint {
  position: number;
  height: number;
}

function getSignificantHeightPoints(
  crossSection: SlopedInsulationCrossSection,
  heightRanges: FastenerHeightRange[],
  unitScale: number
): HeightPoint[] {
  const points = new Set<number>();

  // Add start and end points
  points.add(0);
  points.add(1);

  // Add all low points from cross section
  crossSection.lowPoints.forEach((t) => points.add(t));

  // Add high points between low points
  for (let i = 0; i < crossSection.lowPoints.length - 1; i++) {
    const midPoint =
      (crossSection.lowPoints[i] + crossSection.lowPoints[i + 1]) / 2;
    points.add(midPoint);
  }

  // For each height range boundary, find where it intersects with the height profile
  heightRanges.forEach((range) => {
    // Check each segment between existing points
    const existingPoints = Array.from(points).sort((a, b) => a - b);
    for (let i = 0; i < existingPoints.length - 1; i++) {
      const start = existingPoints[i];
      const end = existingPoints[i + 1];
      const startHeight = calculateHeightAtPosition(
        start,
        crossSection,
        unitScale
      );
      const endHeight = calculateHeightAtPosition(end, crossSection, unitScale);

      // Check if range boundaries intersect this segment
      [range.minHeight, range.maxHeight].forEach((boundaryHeight) => {
        if (
          boundaryHeight >= Math.min(startHeight, endHeight) &&
          boundaryHeight <= Math.max(startHeight, endHeight)
        ) {
          // Find exact position where height equals boundary
          const t = (boundaryHeight - startHeight) / (endHeight - startHeight);
          const pos = start + t * (end - start);
          points.add(pos);
        }
      });
    }
  });

  // Convert to array of HeightPoints, sort by position
  return Array.from(points)
    .sort((a, b) => a - b)
    .map((pos) => ({
      position: pos,
      height: calculateHeightAtPosition(pos, crossSection, unitScale),
    }));
}
