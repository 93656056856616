import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useMutation, useQuery, useSubscription } from 'urql';
import {
  faMagicWandSparkles,
  faMaximize,
  faMinimize,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import {
  AutoShapeAlgorithmType,
  AutoShapeDeepFragment,
  GetAutoShapesForProjectDocument,
  CreateAutoShapeDocument,
  DeleteAutoShapeDocument,
  RunExternalWallFinishes3DDocument,
  UpdateAutoShapeDocument,
  RunRoomDetection2DDocument,
  OnAutoShapeStatusUpdatedDocument,
  AutoShapeStatus,
  ExternalWallFinishes3DInput,
  RoomDetection2DInput,
} from 'src/gql/graphql';
import { findNextAvailableName } from 'src/utils/naming-utils';
import { Polygon, Polygon2 } from 'src/domain/geometry/geometric-types';
import { AutoShapeAlgorithmOptionsMap } from 'src/components/auto-shapes/types';
import { useUserTenant } from 'src/services/auth-info';

type PolygonWithAttributesResult = {
  polygons: Polygon[];
  attributes: {
    propertySet: string;
    propertyValue: string;
  }[];
};

type Polygon2WithAttributesResult = {
  polygons: Polygon2[];
  attributes: {
    propertySet: string;
    propertyValue: string;
  }[];
};

type AlgorithmResultMap = {
  [AutoShapeAlgorithmType.ExternalWallFinishes_3D]: {
    shapesToCreate: PolygonWithAttributesResult[];
  };
  [AutoShapeAlgorithmType.InternalWallFinishes_3D]: {
    shapesToCreate: PolygonWithAttributesResult[];
  };
  [AutoShapeAlgorithmType.RoomDetection_2D]: {
    shapesToCreate: Polygon2WithAttributesResult[];
  };
  [AutoShapeAlgorithmType.RoomDetection_3D]: {
    shapesToCreate: PolygonWithAttributesResult[];
  };
};

interface AutoShapesContextValue {
  autoShapes: AutoShapeDeepFragment[] | undefined;
  error: Error | undefined;
  createAutoShape: <T extends AutoShapeAlgorithmType>(
    name: string,
    algorithmType: T,
    algorithmInput: AutoShapeAlgorithmOptionsMap[T]
  ) => Promise<AutoShapeDeepFragment | null>;
  deleteAutoShape: (id: string) => Promise<boolean>;
  renameAutoShape: (id: string, name: string) => Promise<boolean>;
  findNextAvailableAutoShapeName: (name: string) => string;
}

const AutoShapesContext = createContext<AutoShapesContextValue | undefined>(
  undefined
);

type AutoShapesProviderProps = {
  projectId: string;
  children: React.ReactNode;
};

export const AutoShapesProvider: React.FC<AutoShapesProviderProps> = ({
  projectId,
  children,
}) => {
  const [{ data, error }] = useQuery({
    query: GetAutoShapesForProjectDocument,
    variables: { projectId },
  });

  const { tenant } = useUserTenant();

  const [, createAutoShapeMutation] = useMutation(CreateAutoShapeDocument);
  const [, deleteAutoShapeMutation] = useMutation(DeleteAutoShapeDocument);
  const [, updateAutoShapeMutation] = useMutation(UpdateAutoShapeDocument);

  const autoShapes = useMemo(() => data?.project?.autoShapes || [], [data]);

  const [, runExternalWallFinishes3D] = useMutation(
    RunExternalWallFinishes3DDocument
  );
  const [, runRoomDetection2D] = useMutation(RunRoomDetection2DDocument);

  const runAutoShapeAlgorithm = useCallback(
    async <T extends AutoShapeAlgorithmType>(
      algorithmType: T,
      algorithmInput: {
        options: AutoShapeAlgorithmOptionsMap[T];
        autoShapeId: string;
        projectId: string;
        tenant: string;
      }
    ) => {
      switch (algorithmType) {
        case AutoShapeAlgorithmType.ExternalWallFinishes_3D:
          console.log('Running ExternalWallFinishes_3D');
          return runExternalWallFinishes3D({
            input: algorithmInput as ExternalWallFinishes3DInput,
          });
        case AutoShapeAlgorithmType.InternalWallFinishes_3D:
          throw new Error('Not implemented');
        case AutoShapeAlgorithmType.RoomDetection_2D:
          console.log('Running RoomDetection_2D');
          return runRoomDetection2D({
            input: algorithmInput as RoomDetection2DInput,
          });
        case AutoShapeAlgorithmType.RoomDetection_3D:
          throw new Error('Not implemented');
      }
    },
    [runExternalWallFinishes3D, runRoomDetection2D]
  );

  const createAutoShape = useCallback(
    async <T extends AutoShapeAlgorithmType>(
      name: string,
      algorithmType: T,
      algorithmOptions: AutoShapeAlgorithmOptionsMap[T]
    ) => {
      try {
        // Create the auto shape
        const { data, error: createError } = await createAutoShapeMutation({
          input: {
            autoShape: {
              name,
              projectId,
              algorithmType,
              algorithmInput: algorithmOptions,
            },
          },
        });

        if (createError) {
          console.error('Create mutation error:', createError);
          throw createError;
        }

        if (!data?.createAutoShape?.autoShape) {
          throw new Error('Failed to create auto shape');
        }
        if (!tenant?.group) {
          throw new Error('Tenant is not set');
        }
        // Run the algorithm
        const result = await runAutoShapeAlgorithm(algorithmType, {
          options: algorithmOptions,
          autoShapeId: data.createAutoShape.autoShape.id,
          tenant: tenant.group,
          projectId: data.createAutoShape.autoShape.projectId,
        });

        const algorithmError = result?.error;

        if (algorithmError) {
          console.error('Algorithm error:', algorithmError);
          throw algorithmError;
        }

        return (
          (data.createAutoShape.autoShape as AutoShapeDeepFragment) ?? null
        );
      } catch (e) {
        console.error('Full error:', e);
        return null;
      }
    },
    [createAutoShapeMutation, projectId, runAutoShapeAlgorithm, tenant?.group]
  );

  const deleteAutoShape = useCallback(
    async (id: string) => {
      try {
        await deleteAutoShapeMutation({ input: { id } });
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    [deleteAutoShapeMutation]
  );

  const renameAutoShape = useCallback(
    async (id: string, name: string) => {
      await updateAutoShapeMutation({
        input: { id, patch: { name } },
      });
      return true;
    },
    [updateAutoShapeMutation]
  );

  const handleAutoShapeSuccess = useCallback(
    async (autoShape: {
      algorithmType: AutoShapeAlgorithmType;
      result: AlgorithmResultMap[AutoShapeAlgorithmType];
    }) => {
      switch (autoShape.algorithmType) {
        case AutoShapeAlgorithmType.ExternalWallFinishes_3D:
          console.log('External wall finishes completed:', autoShape.result);
          break;

        case AutoShapeAlgorithmType.InternalWallFinishes_3D:
          console.log('Internal wall finishes completed:', autoShape.result);
          break;

        case AutoShapeAlgorithmType.RoomDetection_2D:
          console.log('2D room detection completed:', autoShape.result);
          break;

        case AutoShapeAlgorithmType.RoomDetection_3D:
          console.log('3D room detection completed:', autoShape.result);
          break;
      }
    },
    []
  );

  // Listen to AutoShapes changes. The URQL cache will handle updates, so we don't need to use the result here
  const [{ data: autoShapeUpdatedResult }] = useSubscription({
    query: OnAutoShapeStatusUpdatedDocument,
    variables: { projectId },
  });

  useEffect(() => {
    if (autoShapeUpdatedResult?.autoShapeStatusUpdated?.autoShape) {
      const updatedAutoShape =
        autoShapeUpdatedResult.autoShapeStatusUpdated.autoShape;

      if (updatedAutoShape.status === AutoShapeStatus.Completed) {
        if (updatedAutoShape.algorithmType && updatedAutoShape.result) {
          const algorithmType = updatedAutoShape.algorithmType;
          const result = updatedAutoShape.result;
          handleAutoShapeSuccess({ algorithmType, result });
        }
      }
    }
  }, [
    handleAutoShapeSuccess,
    autoShapeUpdatedResult?.autoShapeStatusUpdated?.autoShape,
    projectId,
  ]);

  const findNextAvailableAutoShapeName = useCallback(
    (name: string) => {
      return findNextAvailableName(
        name,
        autoShapes.map((s) => s.name)
      );
    },
    [autoShapes]
  );

  const value = useMemo<AutoShapesContextValue>(
    () => ({
      autoShapes,
      error,
      createAutoShape,
      deleteAutoShape,
      renameAutoShape,
      findNextAvailableAutoShapeName,
    }),
    [
      autoShapes,
      error,
      createAutoShape,
      deleteAutoShape,
      renameAutoShape,
      findNextAvailableAutoShapeName,
    ]
  );

  return (
    <AutoShapesContext.Provider value={value}>
      {children}
    </AutoShapesContext.Provider>
  );
};

// Custom hook to use the context
export const useAutoShapes = (): AutoShapesContextValue => {
  const context = useContext(AutoShapesContext);
  if (!context) {
    throw new Error('useAutoShapes must be used within a AutoShapesProvider');
  }
  return context;
};

interface AlgorithmMetadata {
  name: string;
  description: string;
  icon: IconDefinition;
}

export const getAlgorithmMetadata = (
  algorithmType: AutoShapeAlgorithmType
): AlgorithmMetadata => {
  switch (algorithmType) {
    case AutoShapeAlgorithmType.ExternalWallFinishes_3D:
      return {
        name: 'External Wall Finishes 3D',
        description: 'Automatically detect external wall finishes in 3D',
        icon: faMinimize,
      };
    case AutoShapeAlgorithmType.InternalWallFinishes_3D:
      return {
        name: 'Internal Wall Finishes 3D',
        description: 'Automatically detect internal wall finishes in 3D',
        icon: faMaximize,
      };
    case AutoShapeAlgorithmType.RoomDetection_2D:
      return {
        name: 'Room Detection 2D',
        description: 'Automatically detect rooms in 2D',
        icon: faMagicWandSparkles,
      };
    case AutoShapeAlgorithmType.RoomDetection_3D:
      return {
        name: 'Room Detection 3D',
        description: 'Automatically detect rooms in 3D',
        icon: faMagicWandSparkles,
      };
  }
};
