import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';
import {
  CreateAutoShapeMutation,
  CreateAutoShapeMutationVariables,
  DeleteAutoShapeMutation,
  DeleteAutoShapeMutationVariables,
  UpdateAutoShapeMutation,
  UpdateAutoShapeMutationVariables,
  OnAutoShapeStatusUpdatedSubscription,
  AutoShapeStatus,
} from '../../gql/graphql';

export const createAutoShapeResolver: UpdateResolver = (
  result: CreateAutoShapeMutation,
  args: CreateAutoShapeMutationVariables,
  cache
) => {
  if (result.createAutoShape?.autoShape) {
    const shapesForProject = cache.resolve(
      { __typename: 'Project', id: result.createAutoShape.autoShape.projectId },
      'autoShapes'
    ) as string[] | null;
    const shapeToAdd = cache.keyOfEntity({
      __typename: 'AutoShape',
      id: result.createAutoShape.autoShape.id,
    });
    if (shapesForProject && shapeToAdd) {
      shapesForProject.push(shapeToAdd);
      cache.link(
        {
          __typename: 'Project',
          id: result.createAutoShape.autoShape.projectId,
        },
        'autoShapes',
        shapesForProject
      );
    }
  }
};

export const deleteAutoShapeResolver: UpdateResolver = (
  result: DeleteAutoShapeMutation,
  args: DeleteAutoShapeMutationVariables,
  cache
) => {
  if (result.deleteAutoShape?.autoShape) {
    const autoShape = result.deleteAutoShape.autoShape;
    const projectId = autoShape.projectId;

    // Remove from autoShapes list
    const shapesForProject = cache.resolve(
      { __typename: 'Project', id: projectId },
      'autoShapes'
    ) as string[] | null;
    const shapeToRemove = cache.keyOfEntity({
      __typename: 'AutoShape',
      id: autoShape.id,
    });
    if (shapesForProject && shapeToRemove) {
      cache.link(
        { __typename: 'Project', id: projectId },
        'autoShapes',
        shapesForProject.filter((key) => key !== shapeToRemove)
      );
    }

    // Remove associated shapes
    const shapes = cache.resolve(
      { __typename: 'Project', id: projectId },
      'shapes'
    ) as string[] | null;
    if (shapes) {
      cache.link(
        { __typename: 'Project', id: projectId },
        'shapes',
        shapes.filter((key) => {
          const shape = cache.resolve(key, 'autoShapeId');
          return shape !== autoShape.id;
        })
      );
    }

    // Remove associated sheet shapes
    const sheetShapes = cache.resolve(
      { __typename: 'Project', id: projectId },
      'sheetShapes'
    ) as string[] | null;
    if (sheetShapes) {
      cache.link(
        { __typename: 'Project', id: projectId },
        'sheetShapes',
        sheetShapes.filter((key) => {
          const shape = cache.resolve(key, 'autoShapeId');
          return shape !== autoShape.id;
        })
      );
    }
  }
};

export const updateAutoShapeResolver: UpdateResolver = (
  result: UpdateAutoShapeMutation,
  args: UpdateAutoShapeMutationVariables,
  cache
) => {
  if (result.updateAutoShape?.autoShape) {
    const shapesForProject = cache.resolve(
      { __typename: 'Project', id: result.updateAutoShape.autoShape.projectId },
      'autoShapes'
    ) as string[] | null;
    const shapeKey = cache.keyOfEntity({
      __typename: 'AutoShape',
      id: result.updateAutoShape.autoShape.id,
    });
    if (shapesForProject && shapeKey) {
      cache.link(
        {
          __typename: 'Project',
          id: result.updateAutoShape.autoShape.projectId,
        },
        'autoShapes',
        shapesForProject.map((key) => (key === shapeKey ? shapeKey : key))
      );
    }
  }
};

export const autoShapeStatusUpdatedResolver: UpdateResolver<
  OnAutoShapeStatusUpdatedSubscription,
  { projectId: string }
> = (result, args, cache) => {
  const autoShape = result.autoShapeStatusUpdated?.autoShape;
  if (!autoShape) return;

  // Update the auto shape fragment in cache
  cache.writeFragment(
    gql`
      fragment _ on AutoShape {
        id
        status
        result
      }
    `,
    {
      __typename: 'AutoShape',
      id: autoShape.id,
      status: autoShape.status,
      result: autoShape.result,
    }
  );

  // Update the project's auto shapes link
  const shapesForProject = cache.resolve(
    { __typename: 'Project', id: autoShape.projectId },
    'autoShapes'
  ) as string[] | null;

  const shapeKey = cache.keyOfEntity({
    __typename: 'AutoShape',
    id: autoShape.id,
  });

  if (shapesForProject && shapeKey) {
    cache.link(
      { __typename: 'Project', id: autoShape.projectId },
      'autoShapes',
      shapesForProject.map((key) => (key === shapeKey ? shapeKey : key))
    );
  }

  // Only update shapes caches if the auto shape is completed
  if (autoShape.status === AutoShapeStatus.Completed) {
    // Force refetch of shapes
    cache.invalidate(
      { __typename: 'Project', id: autoShape.projectId },
      'shapes'
    );
    cache.invalidate(
      { __typename: 'Project', id: autoShape.projectId },
      'sheetShapes'
    );
  }
};
