import {
  Data,
  cacheExchange as _cacheExchange,
} from '@urql/exchange-graphcache';
import schema from '../gql/generated-introspection';
import { SheetPageCalibration } from '../gql/graphql';
import {
  createCommentResolver,
  deleteCommentResolver,
} from './cache-update-resolvers/comments-resolvers';
import {
  deleteModelResolver,
  deleteModelVersionResolver,
  modelStatusUpdatedResolver,
} from './cache-update-resolvers/model-resolvers';
import {
  createOrderColumnResolver,
  deleteOrderColumnResolver,
} from './cache-update-resolvers/order-column-resolvers';
import {
  createOrderEntryResolver,
  flattenSubrowsResolver,
  moveOrCopyOrderEntriesResolver,
} from './cache-update-resolvers/order-entry-resolvers';
import {
  createOrderEntryFromTemplateResolver,
  createTemplateFromOrderEntryResolver,
  deleteOrderEntryTemplateResolver,
} from './cache-update-resolvers/order-entry-template-resolvers';
import {
  createOrderEntryViewResolver,
  deleteOrderEntryViewResolver,
} from './cache-update-resolvers/order-entry-view-resolvers';
import {
  createOrderResolver,
  deleteOrderResolver,
  duplicateOrderResolver,
} from './cache-update-resolvers/order-resolvers';
import {
  createProjectResolver,
  deleteProjectResolver,
} from './cache-update-resolvers/project-resolvers';
import {
  createProjectFromTemplateResolver,
  createTableFromTemplateResolver,
  createTemplateFromProjectResolver,
  deleteProjectTemplateResolver,
} from './cache-update-resolvers/project-template-resolvers';
import {
  createSavedViewResolver,
  deleteSavedViewResolver,
  updateSavedViewResolver,
} from './cache-update-resolvers/saved-views-resolvers';
import {
  createShapeFolderResolver,
  deleteShapeFolderResolver,
} from './cache-update-resolvers/shape-folders-resolvers';
import {
  createShapeResolver,
  deleteShapeResolver,
} from './cache-update-resolvers/shapes-resolvers';
import {
  createSheetPageCalibrationResolver,
  createSheetResolver,
  deleteSheetVersionResolver,
} from './cache-update-resolvers/sheet-resolvers';
import {
  createSheetShapeResolver,
  deleteSheetShapeResolver,
} from './cache-update-resolvers/sheet-shapes-resolvers';
import {
  createAttributeResolver,
  deleteSparkelPropertiesResolver,
} from './cache-update-resolvers/sparkel-attributes-resolvers';
import {
  createUserResolver,
  deleteUserResolver,
  updateUserResolver,
} from './cache-update-resolvers/user-resolvers';
import { askCopilotResolver } from './copilot-resolvers';
import { upsertProtanProjectStepResolver } from './cache-update-resolvers/protan-project-steps-resolver';
import {
  createIssueCommentResolver,
  createIssueResolver,
  deleteIssueResolver,
} from './cache-update-resolvers/issue-resolvers';
import {
  createProjectTagResolver,
  deleteProjectTagResolver,
  updateProjectTagResolver,
} from './cache-update-resolvers/project-tags-resolver';
import {
  autoShapeStatusUpdatedResolver,
  createAutoShapeResolver,
  deleteAutoShapeResolver,
  updateAutoShapeResolver,
} from './cache-update-resolvers/auto-shapes-resolvers';

export const cacheExchange = _cacheExchange({
  schema,
  keys: {
    ProjectSettingsType: () => null,
    LabelType: () => null,
    PropertySetType: () => null,
    ForgeAttributePredicateType: () => null,
    ForgeAttributeQueryType: () => null,
    ForgeAttributeType: () => null,
    ElementIdentifierDbidType: () => null,
    QuantityType: () => null,
    CalculationType: () => null,
    PlaneType: () => null,
    Point3Type: () => null,
    Point2Type: () => null,
    LinearRing3Type: () => null,
    LinearRing2Type: () => null,
    Polygon3Type: () => null,
    Polygon2Type: () => null,
    MultiPolygon3Type: () => null,
    MultiPolygon2Type: () => null,
    SheetScaleType: () => null,
    ForgeAccessToken: () => null,
    CameraPositionType: () => null,
    ExternalAccessToken: () => null,
    TemplateCustomFieldType: () => null,
    ColumnFilterType: () => null,
    CopilotAnswer: () => null,
    CopilotBadQueryResponse: () => null,
    SheetPageCalibration: (data: Data & SheetPageCalibration) =>
      `${data.sheetId}-${data.pageNumber}`,
  },
  resolvers: {
    Query: {
      project: (_, args) => ({ __typename: 'Project', id: args.id }),
      order: (_, args) => ({ __typename: 'Order', id: args.id }),
    },
  },
  updates: {
    Mutation: {
      createUser: createUserResolver,
      deleteUser: deleteUserResolver,
      updateAccessibleProjectsForUser: updateUserResolver,
      createProject: createProjectResolver,
      deleteProject: deleteProjectResolver,
      deleteModel: deleteModelResolver,
      deleteModelVersion: deleteModelVersionResolver,
      createOrder: createOrderResolver,
      duplicateOrder: duplicateOrderResolver,
      deleteOrder: deleteOrderResolver,
      createShape: createShapeResolver,
      deleteShape: deleteShapeResolver,
      deleteSheetVersion: deleteSheetVersionResolver,
      createSheetShape: createSheetShapeResolver,
      deleteSheetShape: deleteSheetShapeResolver,
      createShapeFolder: createShapeFolderResolver,
      deleteShapeFolder: deleteShapeFolderResolver,
      flattenSubrows: flattenSubrowsResolver,
      createSavedView: createSavedViewResolver,
      updateSavedView: updateSavedViewResolver,
      deleteSavedView: deleteSavedViewResolver,
      createOrderEntry: createOrderEntryResolver,
      createOrderColumn: createOrderColumnResolver,
      deleteOrderColumn: deleteOrderColumnResolver,
      createTemplateFromOrderEntry: createTemplateFromOrderEntryResolver,
      createOrderEntryFromTemplate: createOrderEntryFromTemplateResolver,
      deleteOrderEntryTemplate: deleteOrderEntryTemplateResolver,
      createTableFromTemplate: createTableFromTemplateResolver,
      moveOrCopyOrderEntries: moveOrCopyOrderEntriesResolver,
      deleteProjectTemplate: deleteProjectTemplateResolver,
      createTemplateFromProject: createTemplateFromProjectResolver,
      createProjectFromTemplate: createProjectFromTemplateResolver,
      createOrderEntryView: createOrderEntryViewResolver,
      deleteOrderEntryView: deleteOrderEntryViewResolver,
      createSheet: createSheetResolver,
      createSheetPageCalibration: createSheetPageCalibrationResolver,
      createComment: createCommentResolver,
      deleteComment: deleteCommentResolver,
      bulkUpsertSparkelProperties: createAttributeResolver,
      bulkDeleteSparkelProperties: deleteSparkelPropertiesResolver,
      upsertProtanProjectStep: upsertProtanProjectStepResolver,
      askCopilot: askCopilotResolver,
      createIssue: createIssueResolver,
      deleteIssue: deleteIssueResolver,
      createIssueComment: createIssueCommentResolver,
      createProjectTag: createProjectTagResolver,
      updateProjectTag: updateProjectTagResolver,
      deleteProjectTag: deleteProjectTagResolver,
      createAutoShape: createAutoShapeResolver,
      deleteAutoShape: deleteAutoShapeResolver,
      updateAutoShape: updateAutoShapeResolver,
    },
    Subscription: {
      modelStatusUpdated: modelStatusUpdatedResolver,
      autoShapeStatusUpdated: autoShapeStatusUpdatedResolver,
    },
  },
});
