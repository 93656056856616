import { RectangularProduct, SlopedProduct } from './SlopedInsulation';

// Constants for surface resistance (m²K/W)
const SURFACE_RESISTANCE = {
  INTERNAL: 0.1, // For internal surface
  EXTERNAL: 0.04, // For external surface
};

type Product = RectangularProduct | SlopedProduct;

function isSlopedProduct(product: Product): product is SlopedProduct {
  return 'dimensions' in product && 'maxHeight' in product.dimensions;
}

export function calculateR0(products: RectangularProduct[]): number {
  // Start with surface resistances
  let totalR = SURFACE_RESISTANCE.INTERNAL + SURFACE_RESISTANCE.EXTERNAL;

  // Add R-values for all non-tapered layers
  for (const product of products) {
    // R = d/λ where d is thickness and λ is thermal conductivity
    totalR += product.dimensions.height / product.thermalConductivity;
  }

  return totalR;
}

export function calculateR2(taperedProduct: SlopedProduct): number {
  // R2 here represents only the triangular part of the tapered layer
  // R2 = (d2-d1)/λ where (d2-d1) is the height difference
  return (
    (taperedProduct.dimensions.maxHeight -
      taperedProduct.dimensions.minHeight) /
    taperedProduct.thermalConductivity
  );
}

export function calculateSliceUValue(products: Product[]): number {
  // Find the tapered product (assuming one per slice)
  const taperedProduct = products.find(isSlopedProduct);
  if (!taperedProduct) {
    // If no tapered product, use simple R-value calculation
    const totalR = calculateR0(products as RectangularProduct[]);
    return 1 / totalR;
  }

  // Create a rectangular product for the base part of the tapered layer
  const rectangularPartOfSlopedProduct = {
    ...taperedProduct,
    dimensions: {
      height: taperedProduct.dimensions.minHeight,
    },
  };

  // Calculate R0 including the rectangular base of the tapered layer
  const productsWithoutTapered = [
    ...products.filter((p) => !isSlopedProduct(p)),
    rectangularPartOfSlopedProduct,
  ];
  const R0 = calculateR0(productsWithoutTapered as RectangularProduct[]);

  // Calculate R2 for just the triangular part of the tapered layer
  const R2 = calculateR2(taperedProduct as SlopedProduct);

  // Calculate U using the formula: U = 1/R2 * ln(1 + R2/R0)
  const U = (1 / R2) * Math.log(1 + R2 / R0);

  return U;
}

export function calculateRoofUValue(
  slicesWithArea: {
    products: Product[];
    area: number;
  }[]
): number {
  let totalWeightedU = 0;
  let totalArea = 0;

  for (const slice of slicesWithArea) {
    const sliceU = calculateSliceUValue(slice.products);
    totalWeightedU += sliceU * slice.area;
    totalArea += slice.area;
  }

  // Calculate overall U-value using area-weighted average
  return totalWeightedU / totalArea;
}
