import { Map as IMap } from 'immutable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ModelsMap, ShapesMap } from '../../components/common/ForgeViewer';
import {
  getExtrudedPolygonGeometry,
  getPolygonGeometry,
} from '../../components/orderContractor/shapes/util';
import {
  toMultiPolygon,
  toPlane,
} from '../../domain/geometry/algorithms/util/type-mapping';
import { ShapeDeepFragment } from '../../gql/graphql';

// https://forge.autodesk.com/blog/snappy-viewer-tools

const LINE_RADIUS = 0.07;
const POINT_SHAPE_RADIUS = 0.2;
const SHAPE_COLOR = 0x718096;

const createShapeMaterial = () =>
  new THREE.MeshPhongMaterial({
    color: new THREE.Color().setHex(SHAPE_COLOR),
    transparent: true,
    opacity: 0.5,
    depthTest: true,
  });

const createShapeBasicMaterial = () =>
  new THREE.MeshBasicMaterial({
    color: new THREE.Color().setHex(SHAPE_COLOR),
    transparent: true,
    opacity: 0.5,
  });

// Unique identifier for shapes for an order id
// Using btoa to encode the project id to base64 to match the way we link to BIM elements
export function getShapeUrn(projectId: string) {
  return btoa(projectId);
}

export type ShapesManager = {
  renderShapes: (urn: string, shapes: ShapeDeepFragment[]) => void;
  unrenderShapes: (urn: string) => void;
  getShape: (urn: string, dbId: number) => ShapeDeepFragment | undefined;
  renderedShapes: ShapesMap;
  loadedShapeModels: ModelsMap;
};

// A hook for rendering shapes in the viewer
export function useShapesManager(
  viewer: Autodesk.Viewing.Viewer3D | null
): ShapesManager {
  const [sceneBuilder, setSceneBuilder] =
    useState<Autodesk.Viewing.SceneBuilder>();
  const [modelBuilders, setModelBuilders] = useState<
    IMap<string, Autodesk.Viewing.ModelBuilder>
  >(IMap());
  const [shapesByUrn, setShapesByUrn] = useState<
    IMap<string, ShapeDeepFragment[]>
  >(IMap());

  useEffect(() => {
    async function loadSceneBuilder() {
      if (viewer) {
        const sceneBuilder = (await viewer.loadExtension(
          'Autodesk.Viewing.SceneBuilder'
        )) as Autodesk.Viewing.SceneBuilder;
        setSceneBuilder(sceneBuilder);
      }
    }
    loadSceneBuilder();
  }, [viewer]);

  useEffect(() => {
    async function loadModelBuilders() {
      if (!sceneBuilder) {
        return;
      }

      let newModelBuilders = IMap<string, Autodesk.Viewing.ModelBuilder>();
      for (const [urn] of shapesByUrn) {
        if (modelBuilders.get(urn)) {
          continue;
        }
        const modelBuilder = await loadModelBuilder(sceneBuilder, urn);
        newModelBuilders = newModelBuilders.set(urn, modelBuilder);
      }
      if (newModelBuilders.size > 0) {
        setModelBuilders((modelBuilders) =>
          modelBuilders.merge(newModelBuilders)
        );
      }
    }
    loadModelBuilders();
  }, [modelBuilders, sceneBuilder, shapesByUrn]);

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (!sceneBuilder) {
      return;
    }
    let renderedFragments = new Map<number, Autodesk.Viewing.ModelBuilder>();
    let renderedShapes: ShapeDeepFragment[] = [];
    for (const [urn, shapes] of shapesByUrn) {
      const modelBuilder = modelBuilders.get(urn);
      if (!modelBuilder) {
        continue;
      }

      for (const shape of shapes) {
        if (shape.polygon) {
          const polygon = shape.polygon;
          const { plane: shapePlane, multipolygon: shapeMultipolygon } =
            polygon;
          const material = createShapeMaterial();
          const multipolygon = toMultiPolygon(shapeMultipolygon);
          const plane = toPlane(shapePlane);
          for (const polygon of multipolygon.polygons) {
            const geometry = getPolygonGeometry(polygon, plane);
            const fragId = modelBuilder.addFragment(geometry, material);
            modelBuilder.changeFragmentsDbId(fragId, shape.dbId); // Use this dbId in Viewer APIs as usual
            renderedFragments.set(fragId, modelBuilder);
          }
          renderedShapes.push(shape);
        } else if (shape.extrudedPolygon) {
          const extrudedPolygon = shape.extrudedPolygon;
          const {
            plane: shapePlane,
            multipolygon: shapeMultipolygon,
            thickness,
          } = extrudedPolygon;
          const material = createShapeMaterial();
          const multipolygon = toMultiPolygon(shapeMultipolygon);
          const plane = toPlane(shapePlane);
          for (const polygon of multipolygon.polygons) {
            const geometry = getExtrudedPolygonGeometry(
              polygon,
              plane,
              thickness
            );
            const fragId = modelBuilder.addFragment(geometry, material);
            modelBuilder.changeFragmentsDbId(fragId, shape.dbId);
            renderedFragments.set(fragId, modelBuilder);
          }
          renderedShapes.push(shape);
        } else if (shape.shapePoint) {
          const point = shape.shapePoint.point;
          const mesh = getSphereMesh(
            new THREE.Vector3(point.x, point.y, point.z),
            POINT_SHAPE_RADIUS
          );
          mesh.geometry.applyMatrix(mesh.matrix);
          const bufferGeometry = new THREE.BufferGeometry().fromGeometry(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            mesh.geometry
          );
          const material = createShapeBasicMaterial();
          const fragId = modelBuilder.addFragment(bufferGeometry, material);
          modelBuilder.changeFragmentsDbId(fragId, shape.dbId);
          renderedFragments.set(fragId, modelBuilder);
          renderedShapes.push(shape);
        } else if (shape.line) {
          const { points } = shape.line;
          const mesh = new THREE.Mesh();

          for (let i = 0; i < points.length; i++) {
            if (i > 0) {
              const cylinderMesh = getCylinderMesh(
                new THREE.Vector3(
                  points[i - 1].x,
                  points[i - 1].y,
                  points[i - 1].z
                ),
                new THREE.Vector3(points[i].x, points[i].y, points[i].z)
              );
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              mesh.geometry.merge(cylinderMesh.geometry, cylinderMesh.matrix);
            }

            const sphereMesh = getSphereMesh(
              new THREE.Vector3(points[i].x, points[i].y, points[i].z)
            );
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            mesh.geometry.merge(sphereMesh.geometry, sphereMesh.matrix);
          }
          const lineMaterial = createShapeBasicMaterial();

          const bufferGeometry = new THREE.BufferGeometry().fromGeometry(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            mesh.geometry
          );

          const fragId = modelBuilder.addFragment(bufferGeometry, lineMaterial);
          modelBuilder.changeFragmentsDbId(fragId, shape.dbId);
          renderedFragments.set(fragId, modelBuilder);
          renderedShapes.push(shape);
        } else {
          console.error('Unsupported shape', shape);
        }
      }
    }
    return () => {
      renderedFragments.forEach((modelBuilder, fragId) => {
        // Selected objects are not removed unless deselected first
        // Lock selection was the only method I found which is capable of deselecting individual elements
        viewer?.lockSelection(
          Array.from(renderedShapes.map((shape) => shape.dbId)),
          true,
          modelBuilder.model
        );
        modelBuilder.removeFragment(fragId);
      });
    };
  }, [modelBuilders, sceneBuilder, shapesByUrn, viewer]);

  const renderShapes = useCallback(
    (urn: string, shapes: ShapeDeepFragment[]) => {
      setShapesByUrn((currentShapes) => currentShapes.set(urn, shapes));
    },
    []
  );

  const unrenderShapes = useCallback((urn: string) => {
    setShapesByUrn((currentShapes) => currentShapes.remove(urn));
  }, []);

  const getShape = useCallback(
    (urn: string, dbId: number) => {
      return shapesByUrn.get(urn)?.find((shape) => shape.dbId === dbId);
    },
    [shapesByUrn]
  );

  const renderedShapes = useMemo(() => {
    return shapesByUrn.toObject();
  }, [shapesByUrn]);

  const loadedShapeModels = useMemo(() => {
    return Object.fromEntries(
      modelBuilders
        .entrySeq()
        .map(
          ([urn, builder]) =>
            [urn, builder.model] as [string, Autodesk.Viewing.Model]
        )
        .filter(([urn]) => shapesByUrn.has(urn))
    );
  }, [modelBuilders, shapesByUrn]);

  return useMemo(
    () => ({
      renderShapes,
      unrenderShapes,
      getShape,
      renderedShapes,
      loadedShapeModels,
    }),
    [getShape, renderShapes, loadedShapeModels, renderedShapes, unrenderShapes]
  );
}
async function loadModelBuilder(
  sceneBuilder: Autodesk.Viewing.SceneBuilder,
  urn: string
) {
  const modelBuilder = await sceneBuilder.addNewModel({
    conserveMemory: true,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    modelNameOverride: atob(urn),
  });

  const t = modelBuilder.instanceTree as Autodesk.Viewing.InstanceTree;
  // HACK: Changes the node type of the root node of the model (1e-9) created with modelBuilder to NODE_TYPE 5 = Model instead of the default which is 0.
  // A node type of 0 means that the model instance itself is selected instead of the custom geometries when using selection mode FIRST_OBJECT
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  t.nodeAccess.setNodeFlags(-1e9, 5);

  return modelBuilder;
}

function getCylinderMesh(pointA: THREE.Vector3, pointB: THREE.Vector3) {
  const direction = new THREE.Vector3().subVectors(pointA, pointB);

  const cylinder = new THREE.CylinderGeometry(
    LINE_RADIUS,
    LINE_RADIUS,
    direction.length()
  );

  //shift it so one end rests on the origin
  cylinder.applyMatrix(
    new THREE.Matrix4().makeTranslation(0, direction.length() / 2, 0)
  );

  // rotate it the right way for lookAt to work
  cylinder.applyMatrix(
    new THREE.Matrix4().makeRotationX(THREE.Math.degToRad(90))
  );

  const mesh = new THREE.Mesh(cylinder, new THREE.MeshBasicMaterial());

  // Position it where we want
  mesh.position.copy(pointA);
  // And make it point to where we want
  mesh.lookAt(pointB);

  mesh.updateMatrix();

  return mesh;
}

function getSphereMesh(point: THREE.Vector3, radius = LINE_RADIUS) {
  const sphere = new THREE.SphereGeometry(radius);
  const mesh = new THREE.Mesh(sphere, new THREE.MeshBasicMaterial());
  mesh.position.copy(point);
  mesh.updateMatrix();
  return mesh;
}
