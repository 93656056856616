import React, { useMemo } from 'react';
import { GridItem, Heading, Image } from '@chakra-ui/react';
import { useMatches } from 'react-router-dom';
import PlaceholderText from '../text/PlaceholderText';
import noModelsPlaceholder from '../../assets/img/no-models-placeholder.svg';
import noSheetsPlaceholder from '../../assets/img/no-sheets-placeholder.svg';
import { useViewerMode, ViewerMode } from './viewer-mode';
import { useSheetViewer } from './SheetViewer';

type NoDataPlaceholderProps = {
  hasModels: boolean;
  loaded: boolean;
};

type ProjectPageHandle = {
  hideViewer?: boolean;
};

export default function NoDataPlaceholder({
  hasModels,
  loaded,
}: NoDataPlaceholderProps): React.ReactElement | null {
  const { viewerMode } = useViewerMode();
  const { activeSheetId } = useSheetViewer();

  const matches = useMatches();
  const shouldHideViewer = useMemo(
    () => matches.some((m) => (m.handle as ProjectPageHandle)?.hideViewer),
    [matches]
  );

  // A slight hack, but it'll work for now, since since we "auto-select" the first sheet
  const hasSheets = activeSheetId !== null;

  // Show nothing if we're still loading models or sheets
  if (!loaded || shouldHideViewer) {
    return null;
  }

  if (!hasModels && viewerMode === ViewerMode.Models) {
    return (
      <GridItem
        textAlign={'center'}
        zIndex={0}
        area="sidebar-left-end / sidebar-left-end / sidebar-right-start / sidebar-right-start"
        display="flex"
        flexDirection={'column'}
        justifyContent="center"
        sx={{
          '@media (max-width: 1200px)': {
            paddingRight: 2,
            gridArea:
              'sidebar-left-start / sidebar-left-end / sidebar-right-end / sidebar-right-end',
          },
        }}
      >
        <Image
          alt="model placeholder"
          objectFit={'contain'}
          src={noModelsPlaceholder}
          maxHeight={300}
        />
        <Heading color={'gray.500'} size={'lg'} as="h3">
          No models uploaded
        </Heading>
        <PlaceholderText>Upload a model to get started</PlaceholderText>
      </GridItem>
    );
  } else if (!hasSheets && viewerMode === ViewerMode.Sheets) {
    return (
      <GridItem
        textAlign={'center'}
        zIndex={0}
        area="sidebar-left-end / sidebar-left-end / sidebar-right-start / sidebar-right-start"
        display="flex"
        flexDirection={'column'}
        justifyContent="center"
        sx={{
          '@media (max-width: 1200px)': {
            paddingRight: 2,
            gridArea:
              'sidebar-left-start / sidebar-left-end / sidebar-right-end / sidebar-right-end',
          },
        }}
      >
        <Image
          alt="model placeholder"
          objectFit={'contain'}
          src={noSheetsPlaceholder}
          maxHeight={300}
        />
        <Heading color={'gray.500'} size={'lg'} as="h3">
          No sheets uploaded
        </Heading>
        <PlaceholderText>
          Upload a PDF to get started with sheets
        </PlaceholderText>
      </GridItem>
    );
  } else {
    return null;
  }
}
